import React from 'react';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';

function AlertMessage({ message, success }) {
    const IconComponent = success ? CheckCircleFill : XCircleFill;
    const alertColorClass = success ? 'success' : 'error';

    return (
        <div className={`alert-container ${alertColorClass}`}>
            <div className='alert-message'>
                <IconComponent className='me-2' size={40} />
                {message}
            </div>
        </div>
    );
}

export default AlertMessage;