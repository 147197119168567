import React from "react";

import {
  Clock,
  Facebook,
  GeoAlt,
  Github,
  Instagram,
  Linkedin,
  Telephone,
  Youtube,
} from "react-bootstrap-icons";
import logoSY from "../../assets/LogoSY.jpg";
import { Link } from "react-router-dom";

const MyFooter = () => {
  return (
    <>
      {/* <footer className="bg-dark text-light py-3 provaFooter">
        <div className="container">
          <div className="d-flex justify-content-around mt-4">
            <div>
              <Instagram className="" size={30} />

              <Facebook className="mx-3" size={30} />

              <Linkedin size={30} />

              <Github className="mx-3" size={30} />

              <p className="mt-3">Centro assistenza</p>
              <p className="mt-3">Note legali</p>
              <p className="mt-3">2023 Symbiotech Formazione</p>
            </div>

            <div>
              <br />
              <p className="mt-4">Contattaci</p>
              <p className="mt-3">Media Center</p>
              <p className="mt-3">Informazioni</p>
            </div>
          </div>
        </div>
      </footer> */}

      {/* -------------------------------- SCHERMO GRANDE */}
      <footer className="myFooter py-3 incollaFooter d-none d-md-flex d-xxl-flex ">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="colonnaFooter">
              <Link to={"https://www.symbiotech.it/"}>
                <img
                  className="mb-2 mt-1 myFooterImg"
                  alt="logo"
                  src={logoSY}
                ></img>
              </Link>
              <div className="mt-1 centraColFooter">
                <div className="mt-1 centraColFooter text-start">
                  <p className="m-0">SymbioTech SRL</p>

                  <p className="my-2">P.IVA 11804800016</p>

                  <p className="m-0">REA: TO - 1242128</p>
                </div>
              </div>
            </div>

            <div className="colonnaFooter">
              <div className="margin_fcolonnaCentr">
                <Link
                  to={"https://www.linkedin.com/company/symbiotech-it/"}
                  target="_blank"
                >
                  <Linkedin size={25} />
                </Link>
                <Link
                  to={"https://www.youtube.com/@symbiotech2706"}
                  target="_blank"
                  className="link_youtube"
                >
                  <Youtube className="mx-3" size={30} />
                </Link>
                <Link
                  to={"https://www.instagram.com/symbiotech_adsk/"}
                  target="_blank"
                  className="link_instagram"
                >
                  <Instagram className="qweasd" size={25} />
                </Link>
              </div>
              <div className="mt-3">
                <Telephone className="mb-1 me-1" size={20} />
                <span>+39 0113823362</span>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <GeoAlt size={20} />
                <div className="ms-2">
                  <span>Via Enrico De Nicola, 17</span>
                  <br />
                  <span>10036 Settimo Torinese (TO)</span>
                </div>
              </div>
            </div>

            <div className="colonnaFooter">
              <div className="centraColFooter  text-end">
                <h6 className="mt-3">LINK UTILI</h6>{" "}
                <div className="mt-1">
                  <Link
                    to={"https://www.symbiotech.it/webinar/"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">Calendario Webinar</span>
                  </Link>
                  <br />
                  <Link
                    to={"https://www.symbiotech.it/corsi-autodesk/"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">Calendario Corsi</span>
                  </Link>
                  <br />
                  <Link
                    to={"https://www.symbiotech.it/symbiotech-app-autodesk/"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">App SymbioTech</span>
                  </Link>{" "}
                  <br />
                  <Link
                    to={"https://www.symbiotech.it/#contatti"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">Contatti</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* -------------------------------- SCHERMO MEDIO */}
      <footer className="myFooter py-3 incollaFooter d-none d-sm-block d-md-none ">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="colonnaFooter">
              <Link to={"https://www.symbiotech.it/"}>
                <img
                  className="mb-2 mt-1 myFooterImg"
                  alt="logo"
                  src={logoSY}
                ></img>
              </Link>
              <div className="mt-1 centraColFooter">
                <div className="mt-1 centraColFooter ">
                  <p className="m-0">SymbioTech SRL</p>

                  <p className="my-2">P.IVA 11804800016</p>

                  <p className="m-0">REA: TO - 1242128</p>
                </div>
              </div>
            </div>

            <div className="colonnaFooter">
              <div className="margin_fcolonnaCentr">
                <Link
                  to={"https://www.linkedin.com/company/symbiotech-it/"}
                  target="_blank"
                >
                  <Linkedin size={25} />
                </Link>
                <Link
                  to={"https://www.youtube.com/@symbiotech2706"}
                  target="_blank"
                  className="link_youtube"
                >
                  <Youtube className="mx-3" size={30} />
                </Link>
                <Link
                  to={"https://www.instagram.com/symbiotech_adsk/"}
                  target="_blank"
                  className="link_instagram"
                >
                  <Instagram className="qweasd" size={25} />
                </Link>
              </div>
              <div className="mt-3">
                <Telephone className="mb-1 me-1" size={20} />
                <span>+39 0113823362</span>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <GeoAlt size={20} />
                <div className="ms-2">
                  <span>Via Enrico De Nicola, 17</span>
                  <br />
                  <span>10036 Settimo Torinese (TO)</span>
                </div>
              </div>
            </div>

            {/* <div className="colonnaFooter">
              <div className="centraColFooter  text-end">
                <h6 className="mt-3">LINK UTILI</h6>{" "}
                <div className="mt-1">
                  <Link
                    to={"https://www.symbiotech.it/webinar/"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">Calendario Webinar</span>
                  </Link>
                  <br />
                  <Link
                    to={"https://www.symbiotech.it/corsi-autodesk/"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">Calendario Corsi</span>
                  </Link>
                  <br />
                  <Link
                    to={"https://www.symbiotech.it/symbiotech-app-autodesk/"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">App SymbioTech</span>
                  </Link>{" "}
                  <br />
                  <Link
                    to={"https://www.symbiotech.it/#contatti"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">Contatti</span>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      {/* -------------------------------- SCHERMO PICCOLO */}
      <footer className="myFooter py-3 incollaFooter d-sm-none">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="colonnaFooter">
              <Link to={"https://www.symbiotech.it/"}>
                <img
                  className="mb-2 mt-1 myFooterImg"
                  alt="logo"
                  src={logoSY}
                ></img>
              </Link>
              <div className="mt-2 centraColFooter">
                <Link
                  to={"https://www.linkedin.com/company/symbiotech-it/"}
                  target="_blank"
                >
                  <Linkedin size={25} />
                </Link>
                <Link
                  to={"https://www.youtube.com/@symbiotech2706"}
                  target="_blank"
                  className="link_youtube"
                >
                  <Youtube className="mx-3" size={30} />
                </Link>
                <Link
                  to={"https://www.instagram.com/symbiotech_adsk/"}
                  target="_blank"
                  className="link_instagram"
                >
                  <Instagram className="qweasd" size={25} />
                </Link>
              </div>
              <div className="mt-2 centraColFooter">
                <div className="mt-1 centraColFooter ">
                  <p className="m-0">SymbioTech SRL</p>

                  <p className="my-2">P.IVA 11804800016</p>

                  <p className="m-0">REA: TO - 1242128</p>
                </div>
              </div>
              <div className="mt-3">
                <Telephone className="mb-1 me-1" size={20} />
                <span>+39 0113823362</span>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <GeoAlt size={20} />
                <div className="ms-2">
                  <span>Via Enrico De Nicola, 17</span>
                  <br />
                  <span>10036 Settimo Torinese (TO)</span>
                </div>
              </div>
            </div>

            <div className="colonnaFooter">
              <div className="margin_fcolonnaCentr"></div>
            </div>

            {/* <div className="colonnaFooter">
              <div className="centraColFooter  text-end">
                <h6 className="mt-3">LINK UTILI</h6>{" "}
                <div className="mt-1">
                  <Link
                    to={"https://www.symbiotech.it/webinar/"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">Calendario Webinar</span>
                  </Link>
                  <br />
                  <Link
                    to={"https://www.symbiotech.it/corsi-autodesk/"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">Calendario Corsi</span>
                  </Link>
                  <br />
                  <Link
                    to={"https://www.symbiotech.it/symbiotech-app-autodesk/"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">App SymbioTech</span>
                  </Link>{" "}
                  <br />
                  <Link
                    to={"https://www.symbiotech.it/#contatti"}
                    target="_blank"
                    className="clear_link_footer"
                  >
                    <span className="mt-3">Contatti</span>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default MyFooter;
