import React from "react";
import Attenzione from "../assets/attenzione.png";
import { Container } from "react-bootstrap";

const WorkInProgressWebinar = () => {
  return (
    <>
      <div className="margin_T150 text-center">
        <h1>WORK IN PROGRESS</h1>
        <img className="mb-4 imgWIP" src={Attenzione} alt="" />
        <h6>Contenuto non ancora disponibile</h6>
        <h4>Stiamo lavorando per voi</h4>
        <Container className="my-5">
          <h2>Nuovi contenuti gratuiti su Inventor e Vault!</h2>
          <p>
            Scopri un mondo di risorse gratuite dedicate ad Autodesk Inventor e
            Vault. Articoli, tutorial e webinar per approfondire le tue
            conoscenze e migliorare la tua produttività. Tutto ciò che ti serve
            per diventare un esperto.
          </p>
        </Container>
      </div>
    </>
  );
};

export default WorkInProgressWebinar;
