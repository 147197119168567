import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
//import "./styles.css";
//import MainLayout from "../";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import AxiosClient from "../client/client";
import { Axios } from "axios";
//import { useNavigate } from "react-router-dom";

const PassDimenticata = () => {
  const client = new AxiosClient();
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    newData: "",
    confirmPass: "", // Aggiunto il campo per la conferma della password
  });

  const [file, setFile] = useState(null);

  const onChangeSetFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (formData.newData !== formData.confirmPass) {
      setIsLoading(false);
      setFailedMessage("Le password non corrispondono.");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
      return;
    }

    const formDataToSend = { ...formData };
    delete formDataToSend.confirmPass;

    try {
      const response = await client.patch(`/lostPassword`, formDataToSend, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.statusCode === 201) {
        console.log("Email inviata con successo!");
        setIsLoading(false);
        setSuccessMessage("Email inviata con successo!");
        setTimeout(() => {
          setSuccessMessage(null);
          //navigate('/home')
        }, 3000);
      } else if (response.statusCode === 404) {
        console.log("Email non registrata: ", formData.email);
        setIsLoading(false);
        setSuccessMessage("Email non registrata!");
        setTimeout(() => {
          setSuccessMessage(null);
          //navigate('/home')
        }, 3000);
      } else {
        setIsLoading(false);
        console.error("Email non registrata!");
        setFailedMessage("Email non registrata!");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    } catch (e) {
      setIsLoading(false);
      console.error("Errore nella richiesta al server", e);
      setFailedMessage("Errore nella richiesta al server");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" />
        </div>
      )}

      <Container className="new-blog-container asd">
        <h1 className="mb-4">Imposta una nuova Password</h1>
        <h5 className="my-3">
          La nuova password dovrà essere confermata via email per renderla
          attiva
        </h5>

        <Form encType="multipart/form-data" onSubmit={onSubmit}>
          <Form.Group controlId="email-form" className="mt-3">
            <Form.Label className="fw-bold">Email</Form.Label>
            <Form.Control
              required
              size="lg"
              name="email"
              onChange={handleInputChange}
              placeholder="Email"
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="newData-form" className="mt-3">
            <Form.Label className="fw-bold">Nuova Password</Form.Label>
            <Form.Control
              required
              size="lg"
              type="password"
              name="newData"
              onChange={handleInputChange}
              placeholder="Password"
            />
          </Form.Group>

          <Form.Group controlId="confirmPass-form" className="mt-3">
            <Form.Label className="fw-bold">Conferma Password</Form.Label>
            <Form.Control
              required
              size="lg"
              type="password"
              name="confirmPass"
              value={formData.confirmPass}
              onChange={handleInputChange}
              placeholder="Password"
            />
          </Form.Group>

          <Form.Group className="d-flex mt-3 justify-content-end">
            <Button type="reset" size="lg" variant="outline-dark">
              Reset
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="dark"
              style={{
                marginLeft: "1em",
              }}
            >
              {" "}
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};
export default PassDimenticata;
