import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosClient from "../client/client";
import DOMPurify from "dompurify";
import ReactPlayer from "react-player";
import useSession from "../hooks/useSession";

const ViewVideo = () => {
  const { id } = useParams();
  const session = useSession();
  const client = new AxiosClient();
  const [video, setVideo] = useState([]);

  const getVideo = async () => {
    const response = await client.get(`/video/${id}`);
    setVideo(response);
  };

  useEffect(() => {
    getVideo();
  }, []);

  const videoUrl = video.video?.video;

  const sanitizedHTML = DOMPurify.sanitize(video.video?.descrizione);

  //------------------------------------------------------------------ AUTORIZZAZIONE VISTA VIDEO
  // creo 2 variabili per aver chiaro i 2 array da confrontare
  const corsiAccount = session.course;
  const corsiVideo = [
    video.video?.corso.ramo1,
    video.video?.corso.ramo2,
    video.video?.corso.ramo3,
    video.video?.corso.ramo4,
  ];
  // se il confronto è positivo la variabile authorized diventa true altrimenti false
  let authorized = corsiVideo.some((corsi) => corsiAccount.includes(corsi));

  return (
    <>
      <div className="margin_T180 text-center">
        <h1>{video.video?.titolo}</h1>
        <div className="mt-5">
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        </div>

        {!authorized && <h1>!!! COMPRA IL CORSO !!!</h1>}

        {authorized && (
          <>
            <h1>Video:</h1>
            <ReactPlayer url={videoUrl} controls width="100%" height="auto" />
          </>
        )}
      </div>
    </>
  );
};

export default ViewVideo;
