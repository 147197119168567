// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import AxiosClient from "../client/client";

// const GestionaleAccountID = () => {
//   const { id } = useParams();
//   const client = new AxiosClient();
//   const [data, setData] = useState([]);

//   const GetAccount = async () => {
//     try {
//       const response = await client.get(`/account/${id}`);
//       setData(response);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     GetAccount();
//   }, []);

//   console.log(data.account);

//   return (
//     <>
//       <div className="p-account-details">
//         <p>{data.account?.nome}</p>
//         <p>{data.account?.cognome}</p>
//         <p>{data.account?.email}</p>
//         <p>{data.account?.azienda}</p>
//       </div>
//     </>
//   );
// };

// export default GestionaleAccountID;

///////////////////////////////////////////////////////////////////////////////

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import AxiosClient from "../client/client";
// import { Button, Form } from "react-bootstrap";

// const GestionaleAccountID = () => {
//   const { id } = useParams();
//   const client = new AxiosClient();
//   const [data, setData] = useState([]);
//   const [selectedOptions, setSelectedOptions] = useState([]);

//   const GetAccount = async () => {
//     try {
//       const response = await client.get(`/account/${id}`);
//       setData(response);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     GetAccount();
//   }, []);

//   const handleCheckboxChange = (option) => {
//     if (selectedOptions.includes(option)) {
//       setSelectedOptions(selectedOptions.filter((item) => item !== option));
//     } else {
//       setSelectedOptions([...selectedOptions, option]);
//     }
//   };

//   const AddCourse = async () => {
//     try {
//       const response = await client.patch(
//         `/addCourses/${id}`,
//         { course: selectedOptions },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     AddCourse();
//   };

//   console.log(selectedOptions);
//   console.log(data);

//   return (
//     <>
//       <div className="p-account-details">
//         <p>{data.account?.nome}</p>
//         <p>{data.account?.cognome}</p>
//         <p>{data.account?.email}</p>
//         <p>{data.account?.azienda}</p>

//         <Form onSubmit={handleSubmit}>
//           <label className="mx-3">
//             <input
//               className="me-1"
//               type="checkbox"
//               value="Inventor"
//               checked={selectedOptions.includes("Inventor")}
//               onChange={() => handleCheckboxChange("Inventor")}
//             />
//             Inventor
//           </label>

//           <label className="mx-3">
//             <input
//               className="me-1"
//               type="checkbox"
//               value="Vault"
//               checked={selectedOptions.includes("Vault")}
//               onChange={() => handleCheckboxChange("Vault")}
//             />
//             Vault
//           </label>

//           <Button type="submit">Invia</Button>
//         </Form>
//       </div>
//     </>
//   );
// };

// export default GestionaleAccountID;

//////////////////////////////////////////////////////////////
////// FUNZIONA MA CON LA VECCHIA GRAFICA GRIGIA
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import AxiosClient from "../client/client";
// import { Button, Form } from "react-bootstrap";
// import AlertMessage from "../components/alertMessage/AlertMessage";
// import { PuffLoader } from "react-spinners";

// const GestionaleAccountID = () => {
//   const { id } = useParams();
//   const client = new AxiosClient();
//   const [data, setData] = useState([]);
//   const [allCourse, setAllCourse] = useState([]);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [successMessage, setSuccessMessage] = useState(null);
//   const [failedMessage, setFailedMessage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);

//   const GetAccount = async () => {
//     try {
//       const response = await client.get(`/account/${id}`);
//       setData(response);
//       setSelectedOptions(response.account?.course || []);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const GetAllCourse = async () => {
//     try {
//       const response = await client.get("/courses");
//       setAllCourse(response);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     GetAccount();
//     GetAllCourse();
//   }, []);

//   const AddCourse = async () => {
//     setIsLoading(true);
//     setTimeout(async () => {
//       try {
//         const response = await client.patch(
//           `/addCourses/${id}`,
//           { course: selectedOptions },
//           {
//             headers: {
//               "Content-Type": "application/json",
//             },
//           }
//         );
//         GetAccount();
//         if (response.statusCode === 200) {
//           setIsLoading(false);
//           setSuccessMessage("Corsi modificati con successo!");
//           setTimeout(() => {
//             setSuccessMessage(null);
//             //navigate(`/home`);
//           }, 3000);
//         } else {
//           setIsLoading(false);
//           console.error("Errore nella modifica dei corsi");
//           setFailedMessage("Errore nella modifica dei corsi!");
//           setTimeout(() => {
//             setFailedMessage(null);
//           }, 3000);
//         }
//       } catch (e) {
//         setIsLoading(false);
//         console.log(e, "Errore nell'invio dei dati");
//         setFailedMessage("Errore nella richiesta al server");
//         setTimeout(() => {
//           setFailedMessage(null);
//         }, 3000);
//       }
//     }, 2000);
//   };

//   const handleCheckboxChange = (option) => {
//     // Toggle la selezione/deselezione dell'opzione
//     setSelectedOptions((prevOptions) => {
//       if (prevOptions.includes(option)) {
//         return prevOptions.filter((item) => item !== option);
//       } else {
//         return [...prevOptions, option];
//       }
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     await AddCourse();
//     // Aggiorna i corsi nell'array dei corsi selezionati dopo l'aggiunta
//     GetAccount();
//   };

//   //console.log(selectedOptions);
//   //console.log(data.account?.course);
//   //console.log(allCourse.courses);

//   return (
//     <>
//       {successMessage && (
//         <div>
//           <AlertMessage message={successMessage} success={true} />
//         </div>
//       )}

//       {failedMessage && (
//         <div>
//           <AlertMessage message={failedMessage} success={false} />
//         </div>
//       )}

//       {isLoading && (
//         <div className="alert-container">
//           <PuffLoader color="#ec800f" size={200} />
//         </div>
//       )}

//       {/* <div className="margin_T180 text-center">
//         <div className="text-start margin_YAUTO box_string"> */}
//       <div className="margin_T180 d-flex justify-content-evenly  ">
//         <div>
//           <h2 className="mb-4">Corsi:</h2>
//           <Form onSubmit={handleSubmit} className="checkBoxCorsi text-center">
//             <div className="mt-3">
//               {allCourse.courses &&
//                 allCourse.courses
//                   .sort((a, b) => a.titolo.localeCompare(b.titolo)) //metti in ordine alfabetico
//                   .map((course) => (
//                     <label key={course._id} className="mx-3 my-2">
//                       <input
//                         className="me-1"
//                         type="checkbox"
//                         value={course.titolo}
//                         checked={selectedOptions.includes(course.titolo)}
//                         onChange={() => handleCheckboxChange(course.titolo)}
//                       />
//                       {course.titolo}
//                     </label>
//                   ))}
//             </div>
//             <Button className="my-3" type="submit">
//               Invia
//             </Button>
//           </Form>
//         </div>

//         <div className="text-start box_string">
//           <h2>Dati:</h2>

//           <p className="mt-3">
//             <strong>NOME:</strong> {data.account?.nome}
//           </p>
//           <p>
//             <strong>COGNOME:</strong> {data.account?.cognome}
//           </p>
//           <p>
//             <strong>EMAIL:</strong> {data.account?.email}
//           </p>
//           <p>
//             <strong>DATO FATTURA:</strong> {data.account?.datoFattura}
//           </p>
//           <p>
//             <strong>RUOLO:</strong> {data.account?.role}
//           </p>
//           <p>
//             <strong>STATO:</strong>{" "}
//             {data.account?.verified === true && "Attivo"}
//             {data.account?.verified === false && "Non ancora attivo"}
//           </p>
//         </div>
//       </div>
//     </>
//   );
// };

// export default GestionaleAccountID;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AxiosClient from "../client/client";
import { Button, Form } from "react-bootstrap";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import { nanoid } from "nanoid";
import {
  CaretDownFill,
  CaretRightFill,
  ChevronDown,
  ChevronRight,
  Eye,
  EyeFill,
} from "react-bootstrap-icons";

const GestionaleAccountID = () => {
  const { id } = useParams();
  const client = new AxiosClient();
  const [data, setData] = useState([]);
  const [allCourse, setAllCourse] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [icon, setIcon] = useState(
    <CaretDownFill color="black" size={20} role="button" />
  );

  const [ramo1, setRamo1] = useState([]);
  const [ramo2, setRamo2] = useState([]);
  const [ramo3, setRamo3] = useState([]);
  const [ramo4, setRamo4] = useState([]);

  const GetAccount = async () => {
    try {
      const response = await client.get(`/account/${id}`);
      setData(response);
      setSelectedOptions(response.account?.course || []);
    } catch (error) {
      console.error(error);
    }
  };

  const getCourse = async () => {
    try {
      const response = await client.get("/courses");
      setAllCourse(response);
    } catch (e) {
      console.log(e);
    }
  };

  //get ramo1
  const getRamo1 = async () => {
    try {
      const response = await client.get(`/courses/ramo1`);
      setRamo1(response);
    } catch (e) {
      console.log(e);
    }
  };

  //get ramo2
  const getRamo2 = async (parametro) => {
    try {
      const response = await client.get(`/courses/ramo2/${parametro}`);
      setRamo2((prevRamo2) => ({
        ...prevRamo2,
        [parametro]: response.course || [], // Assicurati che response.course sia un array
      }));
    } catch (e) {
      console.log(e);
    }
  };

  // get ramo3
  const getRamo3 = async (parametro) => {
    try {
      const response = await client.get(`/courses/ramo3/${parametro}`);

      setRamo3((prevRamo3) => ({
        ...prevRamo3,
        [parametro]: response.course || [], // Assicurati che response.course sia un array
      }));
    } catch (e) {
      console.log(e);
    }
  };

  // get ramo4
  const getRamo4 = async (parametro) => {
    try {
      const response = await client.get(`/courses/ramo4/${parametro}`);
      setRamo4((prevRamo4) => ({
        ...prevRamo4,
        [parametro]: response.course || [], // Assicurati che response.course sia un array
      }));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    GetAccount();
    getCourse();
    getRamo1();
  }, []);

  //useEffect per ramo2
  useEffect(() => {
    if (ramo1.course && ramo1.course.length > 0) {
      ramo1.course.forEach((corso) => {
        getRamo2(corso.titolo);
      });
    }
  }, [ramo1]);

  //useEffect per ramo3
  useEffect(() => {
    // Itera sulle chiavi di ramo2
    Object.keys(ramo2).forEach((corsoTitolo) => {
      // Accedi all'array di corsi associato a ogni titolo
      const corsi = ramo2[corsoTitolo];

      // Itera sui corsi e chiama la funzione getRamo3
      corsi.forEach((corso) => {
        getRamo3(corso.titolo);
      });
    });
  }, [ramo2]);

  //useEffect per ramo4
  useEffect(() => {
    Object.keys(ramo3).forEach((corsoTitolo) => {
      const corsi = ramo3[corsoTitolo];
      corsi.forEach((corso) => {
        getRamo4(corso.titolo);
      });
    });
  }, [ramo3]);

  const AddCourse = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      try {
        const response = await client.patch(
          `/addCourses/${id}`,
          { course: selectedOptions },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        GetAccount();
        if (response.statusCode === 200) {
          setIsLoading(false);
          setSuccessMessage("Corsi modificati con successo!");
          setTimeout(() => {
            setSuccessMessage(null);
            //navigate(`/home`);
          }, 3000);
        } else {
          setIsLoading(false);
          console.error("Errore nella modifica dei corsi");
          setFailedMessage("Errore nella modifica dei corsi!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e, "Errore nell'invio dei dati");
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    }, 2000);
  };

  const handleCheckboxChange = (option) => {
    // Toggle la selezione/deselezione dell'opzione
    setSelectedOptions((prevOptions) => {
      if (prevOptions.includes(option)) {
        return prevOptions.filter((item) => item !== option);
      } else {
        return [...prevOptions, option];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await AddCourse();
    // Aggiorna i corsi nell'array dei corsi selezionati dopo l'aggiunta
    GetAccount();
  };

  //console.log(selectedOptions);
  //console.log(data.account?.course);
  //console.log(allCourse.courses);

  const [toggleStates, setToggleStates] = useState({});
  const [toggleStates2, setToggleStates2] = useState({});
  const [toggleStates3, setToggleStates3] = useState({});

  const handleToggle = (index) => {
    setToggleStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  const handleToggle2 = (index, index2) => {
    setToggleStates2((prevStates2) => ({
      ...prevStates2,
      [index]: {
        ...prevStates2[index],
        [index2]: !prevStates2[index]?.[index2],
      },
    }));
  };

  const handleToggle3 = (index, index2, index3) => {
    setToggleStates3((prevStates3) => ({
      ...prevStates3,
      [index]: {
        ...prevStates3[index],
        [index2]: {
          ...prevStates3[index]?.[index2],
          [index3]: !prevStates3[index]?.[index2]?.[index3],
        },
      },
    }));
  };

  const [showChangeRole, setShowChangeRole] = useState(false);

  console.log(showChangeRole);

  const [roleData, setRoleData] = useState({
    role: "user",
  });

  const handleInputRoleChange = (e) => {
    const { name, value } = e.target;
    setRoleData({
      ...roleData,
      [name]: value,
    });
  };

  const changeRole = async (e) => {
    e.preventDefault();
    try {
      const response = await client.patch(`/account/update/${id}`, roleData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.statusCode === 200) {
        console.log("Ruolo modificato con successo");
        setShowChangeRole(false);
        setRoleData({ role: "user" });
        //     setSuccessMessage("Commento modificato con successo!");
        //     getPosts()
        //     setTimeout(() => {
        //         setSuccessMessage(null);
        //     }, 3000);
        GetAccount();
      } else {
        console.error("Errore durante la modifica del ruolo");
        //     setFailedMessage("Errore durante la modifica del commento!");
        //     setTimeout(() => {
        //         setFailedMessage(null);
        //     }, 3000);
      }
    } catch (e) {
      console.error("Errore nella richiesta al server", e);
      // setFailedMessage("Errore nella richiesta al server");
      // setTimeout(() => {
      //     setFailedMessage(null);
      // }, 3000);
    }
  };

  console.log(roleData);

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      {/* <div className="margin_T180 text-center">
        <div className="text-start margin_YAUTO box_string"> */}
      <div className="margin_T180 d-flex justify-content-evenly  ">
        <div>
          <h2 className="mb-4">Corsi:</h2>
          <Form
            onSubmit={handleSubmit}
            className="checkBoxCorsi text-center mb-5"
          >
            {/* <div className="mt-3">
              {allCourse.courses &&
                allCourse.courses
                  .sort((a, b) => a.titolo.localeCompare(b.titolo)) //metti in ordine alfabetico
                  .map((course) => (
                    <label key={course._id} className="mx-3 my-2">
                      <input
                        className="me-1"
                        type="checkbox"
                        value={course.titolo}
                        checked={selectedOptions.includes(course.titolo)}
                        onChange={() => handleCheckboxChange(course.titolo)}
                      />
                      {course.titolo}
                    </label>
                  ))}
            </div> */}

            {/* {ramo1.course &&
              ramo1.course.map((course) => {
                return (
                  <div key={nanoid()} className="prove5 mb-3">
                    <div className="d-flex asd1" key={nanoid()}>
                      {!ramo2[course.titolo]?.length && (
                        <div
                          key={nanoid()}
                          className="me-1 mb-4"
                          style={{ marginLeft: "21px" }}
                        ></div>
                      )}
                      {ramo2[course.titolo]?.length > 0 && (
                        <div key={nanoid()} className="pt-1 me-1"></div>
                      )}
                      <p className="text_ramo1" key={nanoid()}>
                        {course.titolo}
                      </p>
                      <label key={course._id} className="mx-3 my-2">
                        <input
                          className="me-1"
                          type="checkbox"
                          value={course.titolo}
                          checked={selectedOptions.includes(course.titolo)}
                          onChange={() => handleCheckboxChange(course.titolo)}
                        />
                        {course.titolo}
                      </label>
                    </div>
                    <div className="prove6" key={nanoid()}>
                      {ramo2[course.titolo] &&
                        ramo2[course.titolo].map((course2) => {
                          return (
                            <div key={nanoid()}>
                              <div className="d-flex me-2 asd2">
                                {!ramo3[course2.titolo]?.length && (
                                  <div
                                    className="me-1 mb-4"
                                    style={{ marginLeft: "28px" }}
                                  ></div>
                                )}
                                {ramo3[course2.titolo]?.length > 0 && (
                                  <div className="ms-2 me-1 pt-1"></div>
                                )}
                                <p className="py-1 text-light text_ramo2">
                                  {course2.titolo}
                                </p>
                              </div>

                              <div className="prove7">
                                {ramo3[course2.titolo] &&
                                  ramo3[course2.titolo].map((course3) => {
                                    return (
                                      <div key={nanoid()}>
                                        <div className="d-flex align-items-center asd3">
                                          {!ramo4[course3.titolo]?.length && (
                                            <div
                                              className="me-1 mb-4"
                                              style={{ marginLeft: "15px" }}
                                            ></div>
                                          )}
                                          {ramo4[course3.titolo]?.length >
                                            0 && (
                                            <div className="me-1 mb-4 "></div>
                                          )}
                                          <p className="py-1 text_ramo3">
                                            {course3.titolo}
                                          </p>
                                        </div>

                                        {ramo4[course3.titolo] &&
                                          ramo4[course3.titolo].map(
                                            (course4) => {
                                              return (
                                                <div
                                                  className="prove8"
                                                  key={nanoid()}
                                                >
                                                  <div>
                                                    <div
                                                      className="asd4"
                                                      style={{
                                                        marginLeft: "15px",
                                                      }}
                                                    >
                                                      <p className="ramo4Mod text_ramo4">
                                                        {course4.titolo}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })} */}

            {/* FUNZIONA   */}
            {/* {ramo1.course &&
              ramo1.course.map((course) => {
                return (
                  <div key={nanoid()} className=" mb-3">
                    <div className="d-flex" key={nanoid()}>
                      {!ramo2[course.titolo]?.length && (
                        <div
                          key={nanoid()}
                          className="me-1 mb-4"
                          style={{ marginLeft: "21px" }}
                        ></div>
                      )}
                      {ramo2[course.titolo]?.length > 0 && (
                        <div key={nanoid()} className="pt-1 me-1"></div>
                      )}
                      <label key={course._id} className="mx-3 my-2">
                        <input
                          className="me-1"
                          type="checkbox"
                          value={course.titolo}
                          checked={selectedOptions.includes(course.titolo)}
                          onChange={() => handleCheckboxChange(course.titolo)}
                        />
                        1 {course.titolo}
                      </label>
                    </div>
                    <div className="prove6" key={nanoid()}>
                      {ramo2[course.titolo] &&
                        ramo2[course.titolo].map((course2) => {
                          return (
                            <div key={nanoid()}>
                              <div className="d-flex me-2 asd2">
                                {!ramo3[course2.titolo]?.length && (
                                  <div className="me-1 mb-4"></div>
                                )}
                                {ramo3[course2.titolo]?.length > 0 && (
                                  <div className="ms-2 me-1 pt-1"></div>
                                )}
                                <label key={course2._id} className="mx-3 my-2">
                                  <input
                                    className="me-1"
                                    type="checkbox"
                                    value={course2.titolo}
                                    checked={selectedOptions.includes(
                                      course2.titolo
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(course2.titolo)
                                    }
                                  />
                                  2 {course2.titolo}
                                </label>
                              </div>

                              <div className="prove7">
                                {ramo3[course2.titolo] &&
                                  ramo3[course2.titolo].map((course3) => {
                                    return (
                                      <div key={nanoid()}>
                                        <div className="d-flex align-items-center asd3">
                                          {!ramo4[course3.titolo]?.length && (
                                            <div className="me-1 mb-4"></div>
                                          )}
                                          {ramo4[course3.titolo]?.length >
                                            0 && (
                                            <div className="me-1 mb-4 "></div>
                                          )}
                                          <label
                                            key={course3._id}
                                            className="mx-3 my-2"
                                          >
                                            <input
                                              className="me-1"
                                              type="checkbox"
                                              value={course3.titolo}
                                              checked={selectedOptions.includes(
                                                course3.titolo
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  course3.titolo
                                                )
                                              }
                                            />
                                            3 {course3.titolo}
                                          </label>
                                        </div>

                                        {ramo4[course3.titolo] &&
                                          ramo4[course3.titolo].map(
                                            (course4) => {
                                              return (
                                                <div
                                                  className="prove8"
                                                  key={nanoid()}
                                                >
                                                  <div>
                                                    <div className="listaCheck4">
                                                      <label
                                                        key={course3._id}
                                                        className="mx-3 my-2"
                                                      >
                                                        <input
                                                          className="me-1"
                                                          type="checkbox"
                                                          value={course4.titolo}
                                                          checked={selectedOptions.includes(
                                                            course4.titolo
                                                          )}
                                                          onChange={() =>
                                                            handleCheckboxChange(
                                                              course4.titolo
                                                            )
                                                          }
                                                        />
                                                        4 {course4.titolo}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })} */}
            {/* ORDINATO */}
            {/* {ramo1.course &&
              ramo1.course.map((course) => {
                return (
                  <div key={nanoid()} className="text-start">
                    <div className="" key={nanoid()}>
                      {!ramo2[course.titolo]?.length && (
                        <div key={nanoid()} className=""></div>
                      )}
                      {ramo2[course.titolo]?.length > 0 && (
                        <div key={nanoid()} className=""></div>
                      )}
                      <label key={course._id} className="me-3">
                        <input
                          className="me-1"
                          type="checkbox"
                          value={course.titolo}
                          checked={selectedOptions.includes(course.titolo)}
                          onChange={() => handleCheckboxChange(course.titolo)}
                        />
                        1 {course.titolo}
                      </label>
                    </div>
                    <div className="" key={nanoid()}>
                      {ramo2[course.titolo] &&
                        ramo2[course.titolo].map((course2) => {
                          return (
                            <div key={nanoid()}>
                              <div className="">
                                {!ramo3[course2.titolo]?.length && (
                                  <div className=""></div>
                                )}
                                {ramo3[course2.titolo]?.length > 0 && (
                                  <div className=""></div>
                                )}
                                <label key={course2._id} className="me-3">
                                  <input
                                    className="me-1"
                                    type="checkbox"
                                    value={course2.titolo}
                                    checked={selectedOptions.includes(
                                      course2.titolo
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(course2.titolo)
                                    }
                                  />
                                  2 {course2.titolo}
                                </label>
                              </div>

                              <div className="">
                                {ramo3[course2.titolo] &&
                                  ramo3[course2.titolo].map((course3) => {
                                    return (
                                      <div key={nanoid()}>
                                        <div className="">
                                          {!ramo4[course3.titolo]?.length && (
                                            <div className=""></div>
                                          )}
                                          {ramo4[course3.titolo]?.length >
                                            0 && <div className=""></div>}
                                          <label
                                            key={course3._id}
                                            className="me-3"
                                          >
                                            <input
                                              className="me-1"
                                              type="checkbox"
                                              value={course3.titolo}
                                              checked={selectedOptions.includes(
                                                course3.titolo
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  course3.titolo
                                                )
                                              }
                                            />
                                            3 {course3.titolo}
                                          </label>
                                        </div>

                                        {ramo4[course3.titolo] &&
                                          ramo4[course3.titolo].map(
                                            (course4) => {
                                              return (
                                                <div
                                                  className=""
                                                  key={nanoid()}
                                                >
                                                  <div>
                                                    <div className="">
                                                      <label
                                                        key={course3._id}
                                                        className=""
                                                      >
                                                        <input
                                                          className="me-1"
                                                          type="checkbox"
                                                          value={course4.titolo}
                                                          checked={selectedOptions.includes(
                                                            course4.titolo
                                                          )}
                                                          onChange={() =>
                                                            handleCheckboxChange(
                                                              course4.titolo
                                                            )
                                                          }
                                                        />
                                                        4 {course4.titolo}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })} */}

            {/* DEFINITIVO MA SENZA TOOGLE */}
            {/* {ramo1.course &&
              ramo1.course.map((course) => {
                return (
                  <div key={nanoid()} className="text-start mb-2 ">
                    <div className="" key={nanoid()}>
                      <label key={course._id} className="me-3">
                        <input
                          className="me-1"
                          type="checkbox"
                          value={course.titolo}
                          checked={selectedOptions.includes(course.titolo)}
                          onChange={() => handleCheckboxChange(course.titolo)}
                        />
                        1- {course.titolo}
                      </label>
                    </div>
                    <div className="" key={nanoid()}>
                      {ramo2[course.titolo] &&
                        ramo2[course.titolo].map((course2) => {
                          return (
                            <div key={nanoid()}>
                              <div className="check_RAMI_2">
                                <label key={course2._id} className="me-3">
                                  <input
                                    className="me-1"
                                    type="checkbox"
                                    value={course2.titolo}
                                    checked={selectedOptions.includes(
                                      course2.titolo
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(course2.titolo)
                                    }
                                  />
                                  2- {course2.titolo}
                                </label>
                              </div>

                              <div className="">
                                {ramo3[course2.titolo] &&
                                  ramo3[course2.titolo].map((course3) => {
                                    return (
                                      <div key={nanoid()}>
                                        <div className="check_RAMI_3">
                                          <label
                                            key={course3._id}
                                            className="me-3"
                                          >
                                            <input
                                              className="me-1"
                                              type="checkbox"
                                              value={course3.titolo}
                                              checked={selectedOptions.includes(
                                                course3.titolo
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  course3.titolo
                                                )
                                              }
                                            />
                                            3- {course3.titolo}
                                          </label>
                                        </div>

                                        {ramo4[course3.titolo] &&
                                          ramo4[course3.titolo].map(
                                            (course4) => {
                                              return (
                                                <div
                                                  className=""
                                                  key={nanoid()}
                                                >
                                                  <div>
                                                    <div className="check_RAMI_4">
                                                      <label
                                                        key={course3._id}
                                                        className=""
                                                      >
                                                        <input
                                                          className="me-1"
                                                          type="checkbox"
                                                          value={course4.titolo}
                                                          checked={selectedOptions.includes(
                                                            course4.titolo
                                                          )}
                                                          onChange={() =>
                                                            handleCheckboxChange(
                                                              course4.titolo
                                                            )
                                                          }
                                                        />
                                                        4- {course4.titolo}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })} */}
            {ramo1.course &&
              ramo1.course.map((course, index) => {
                return (
                  <div key={nanoid()} className="text-start mb-2 ">
                    <div className="d-flex" key={nanoid()}>
                      <label key={course._id} className="me-1">
                        <input
                          className="me-1"
                          type="checkbox"
                          value={course.titolo}
                          checked={selectedOptions.includes(course.titolo)}
                          onChange={() => handleCheckboxChange(course.titolo)}
                        />
                        <span className="ms-1">{course.titolo}</span>
                      </label>
                      {!ramo2[course.titolo]?.length && (
                        <div key={nanoid()} className=""></div>
                      )}
                      {ramo2[course.titolo]?.length > 0 && (
                        <div
                          key={nanoid()}
                          className=""
                          onClick={() => handleToggle(index)}
                        >
                          {toggleStates[index] ? (
                            <ChevronDown
                              color="black"
                              size={13}
                              role="button"
                            />
                          ) : (
                            <ChevronRight
                              color="black"
                              size={13}
                              role="button"
                            />
                          )}
                        </div>
                      )}
                    </div>
                    {/* --------------2 */}
                    <div className="" key={nanoid()}>
                      {ramo2[course.titolo] &&
                        ramo2[course.titolo].map((course2, index2) => {
                          const isSelected = id === course2._id;
                          return (
                            <div
                              key={nanoid()}
                              className={
                                toggleStates[index] ? "toggleSI" : "toggleNO"
                              }
                            >
                              <div className="check_RAMI_2">
                                <label key={course2._id} className="me-1">
                                  <input
                                    className="me-1"
                                    type="checkbox"
                                    value={course2.titolo}
                                    checked={selectedOptions.includes(
                                      course2.titolo
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(course2.titolo)
                                    }
                                  />
                                  <span className="ms-1">{course2.titolo}</span>
                                </label>
                                {!ramo3[course2.titolo]?.length && (
                                  <div className=""></div>
                                )}
                                {ramo3[course2.titolo]?.length > 0 && (
                                  <div
                                    className="d-inline"
                                    onClick={() => handleToggle2(index, index2)}
                                  >
                                    {toggleStates2[index]?.[index2] ? (
                                      <ChevronDown
                                        color="black"
                                        size={13}
                                        role="button"
                                      />
                                    ) : (
                                      <ChevronRight
                                        color="black"
                                        size={13}
                                        role="button"
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                              {/* --------------3 */}
                              <div className="">
                                {ramo3[course2.titolo] &&
                                  ramo3[course2.titolo].map(
                                    (course3, index3) => {
                                      const isSelected = id === course3._id;
                                      return (
                                        <div
                                          key={nanoid()}
                                          className={
                                            toggleStates2[index]?.[index2]
                                              ? "toggleSI"
                                              : "toggleNO"
                                          }
                                        >
                                          <div className="check_RAMI_3">
                                            <label
                                              key={course3._id}
                                              className="me-1"
                                            >
                                              <input
                                                className="me-1"
                                                type="checkbox"
                                                value={course3.titolo}
                                                checked={selectedOptions.includes(
                                                  course3.titolo
                                                )}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    course3.titolo
                                                  )
                                                }
                                              />
                                              <span className="ms-1">
                                                {course3.titolo}
                                              </span>
                                            </label>
                                            {!ramo4[course3.titolo]?.length && (
                                              <div className=""></div>
                                            )}
                                            {ramo4[course3.titolo]?.length >
                                              0 && (
                                              <div
                                                className="d-inline"
                                                onClick={() =>
                                                  handleToggle3(
                                                    index,
                                                    index2,
                                                    index3
                                                  )
                                                }
                                              >
                                                {toggleStates3[index]?.[
                                                  index2
                                                ]?.[index3] ? (
                                                  <ChevronDown
                                                    color="black"
                                                    size={13}
                                                    role="button"
                                                  />
                                                ) : (
                                                  <ChevronRight
                                                    color="black"
                                                    size={13}
                                                    role="button"
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          {/* --------------4 */}
                                          {ramo4[course3.titolo] &&
                                            ramo4[course3.titolo].map(
                                              (course4) => {
                                                const isSelected =
                                                  id === course4._id;
                                                return (
                                                  <div
                                                    className=""
                                                    key={nanoid()}
                                                  >
                                                    <div
                                                      className={
                                                        toggleStates3[index]?.[
                                                          index2
                                                        ]?.[index3]
                                                          ? "toggleSI"
                                                          : "toggleNO"
                                                      }
                                                    >
                                                      <div className="check_RAMI_4">
                                                        <label
                                                          key={course3._id}
                                                          className=""
                                                        >
                                                          <input
                                                            className="me-1"
                                                            type="checkbox"
                                                            value={
                                                              course4.titolo
                                                            }
                                                            checked={selectedOptions.includes(
                                                              course4.titolo
                                                            )}
                                                            onChange={() =>
                                                              handleCheckboxChange(
                                                                course4.titolo
                                                              )
                                                            }
                                                          />
                                                          <span className="ms-1">
                                                            {course4.titolo}
                                                          </span>
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            <Button className="my-3" type="submit">
              Invia
            </Button>
          </Form>
        </div>

        <div className="text-start box_string">
          <h2>Dati:</h2>

          <p className="mt-3">
            <strong>NOME:</strong> {data.account?.nome}
          </p>
          <p>
            <strong>COGNOME:</strong> {data.account?.cognome}
          </p>
          <p>
            <strong>EMAIL:</strong> {data.account?.email}
          </p>
          <p>
            <strong>DATO FATTURA:</strong> {data.account?.datoFattura}
          </p>

          <p>
            <strong>STATO:</strong>
            {data.account?.verified === true && " Attivo"}
            {data.account?.verified === false && " Non ancora attivo"}
          </p>
          {data.account?.role === "azienda" && (
            <div className="d-flex align-items-center mb-3">
              <p className="m-0">
                <strong>TOT CASHBACK:</strong> {data.account?.totCashback} €
              </p>
              <Link to={`/aziendaInfo/${id}`}>
                <Button className="ms-3">Storico</Button>
              </Link>
            </div>
          )}

          <div className="mb-5">
            <div className="d-flex align-items-center">
              <p className="m-0">
                <strong>RUOLO:</strong> {data.account?.role}
              </p>
              <Button
                className="ms-3"
                onClick={() => {
                  if (showChangeRole === false) {
                    setShowChangeRole(true);
                  } else {
                    setShowChangeRole(false);
                  }
                }}
              >
                {showChangeRole === false && "Cambia ruolo"}
                {showChangeRole === true && "Chiudi"}
              </Button>
            </div>
            {showChangeRole === true && (
              <div>
                <Form onSubmit={changeRole} className="d-flex">
                  <Form.Group controlId="nome-form" className="mt-2">
                    <select
                      id="blog-category"
                      name="role"
                      className="form-control form-control-md"
                      style={{ width: "150px" }}
                      onChange={handleInputRoleChange}
                    >
                      <option value="user">User</option>
                      <option value="azienda">Azienda</option>
                      <option value="admin">Admin</option>
                    </select>
                  </Form.Group>

                  <Button
                    className="ms-3 align-self-end"
                    style={{ height: "37px" }}
                    type="submit"
                  >
                    Salva
                  </Button>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GestionaleAccountID;
