import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HouseFill } from "react-bootstrap-icons";

const NotFound = () => {
  return (
    <>
      <div className="text-center container component-bg margin_T180">
        <h1 className="fw-bold"> 404 </h1>
        <h3 className="fw-bold mb-5">Pagina non trovata</h3>

        <Link to={"/home"}>
          <Button className="mb-3" variant="outline-success">
            Home <HouseFill className="ms-2" size={25} />
          </Button>
        </Link>
      </div>
    </>
  );
};

export default NotFound;
