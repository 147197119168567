import React from "react";
import MyFooter from "../components/footer/MyFooter";
import MyNav from "../components/navbar/MyNav";
import { CartProvider } from "../context/CartContext";

const MainLayout = ({ children }) => {
  return (
    <div className="mainLayout">
      <CartProvider>
        <MyNav />
        {children}
        <MyFooter />
      </CartProvider>
    </div>
  );
};

export default MainLayout;
