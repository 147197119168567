import React, { useEffect, useState } from "react";
import AxiosClient from "../client/client";
import { Link } from "react-router-dom";
import { CaretDownFill, CaretRightFill, Trash3 } from "react-bootstrap-icons";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

const GestionaleVideo = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const client = new AxiosClient();
  const [videos, setVideos] = useState([]);

  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  // GET ACCOUNT
  const getVideos = async () => {
    try {
      setIsLoadingAccounts(true);
      const response = await client.get(
        `/videos/byTitle?title=${searchTerm}&page=${currentPage}`
      );
      setVideos(response);
      setIsLoadingAccounts(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const handleSearchTermChange = (newTerm) => {
    setSearchTerm(newTerm);
    setCurrentPage(1); // Imposta currentPage a 1 quando il termine di ricerca cambia
  };

  useEffect(() => {
    getVideos();
  }, [currentPage, searchTerm]);

  console.log(videos);

  //console.log(accounts.accounts);

  const deleteVideo = async (idVideo) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler eliminare questo account?"
    );

    if (confirmDelete) {
      setIsLoadingDelete(true);

      setTimeout(async () => {
        try {
          const response = await client.delete(`video/delete/${idVideo}`);

          if (response.statusCode === 200) {
            console.log("Eliminazione video avvenuto con successo");
            setIsLoadingDelete(false);
            setSuccessMessage("Video eliminato con successo!");
            getVideos();
            setTimeout(() => {
              setSuccessMessage(null);
            }, 3000);
          } else {
            console.error("Errore durante l'eliminazione del video", response);
            setIsLoadingDelete(false);
            setFailedMessage("Errore durante l'eliminazione del video!");
            setTimeout(() => {
              setFailedMessage(null);
            }, 3000);
          }
        } catch (error) {
          console.log(error);
          setIsLoadingDelete(false);
          setFailedMessage("Errore nella richiesta al server");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      }, 2000);
    }
  };

  const handleDeleteClick = (videoId) => {
    return () => {
      deleteVideo(videoId);
    };
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoadingDelete && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      <div className="margin_T150">
        {/* LISTA ACCOUNT */}
        <div className="container mb-5 gestionale-bg">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="me-3 my-0">Video</h2>

            <input
              className="my_input"
              type="text"
              placeholder="Cerca per titolo"
              value={searchTerm}
              onChange={(e) => handleSearchTermChange(e.target.value)}
            />
          </div>

          {videos &&
            videos.videos
              ?.filter((video) =>
                video.titolo.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((video) => {
                return (
                  <div
                    key={video._id}
                    className="d-flex justify-content-between align-items-center my-4 p-account-box-singlePost"
                  >
                    <Link
                      to={`/gestionaleVideoID/${video._id}`}
                      className="clear_link_black"
                    >
                      <div className="d-flex ms-3">
                        <h5>{video.titolo}</h5>
                      </div>
                    </Link>

                    <Trash3
                      onClick={handleDeleteClick(video._id)}
                      color="red"
                      size={25}
                      role="button"
                      className="mx-3"
                    />
                  </div>
                );
              })}
          <ResponsivePagination
            current={currentPage}
            total={videos && videos.totalPages}
            onPageChange={handlePagination}
          />
        </div>
        <div className="mb-5"></div>
      </div>
    </>
  );
};

export default GestionaleVideo;
