// import React, { useState } from "react";
// import { Form, Button, Modal } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import AlertMessage from "../components/alertMessage/AlertMessage";
// import { EyeFill } from "react-bootstrap-icons";
// import { PuffLoader } from "react-spinners";

// const Login = () => {
//   const navigate = useNavigate();
//   const [loginData, setLoginData] = useState({});
//   const [login, setLogin] = useState(null);
//   const [successMessage, setSuccessMessage] = useState(null);
//   const [failedMessage, setFailedMessage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setLoginData({
//       ...loginData,
//       [name]: value,
//     });
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();

//     setIsLoading(true);
//     setTimeout(async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_SERVER_BASE_URL}/login`,
//           {
//             headers: {
//               "Content-Type": "application/json",
//             },
//             method: "POST",
//             body: JSON.stringify(loginData),
//           }
//         );

//         const data = await response.json();

//         if (data.token) {
//           localStorage.setItem("loggedInUser", JSON.stringify(data.token));
//         }
//         setLogin(data);

//         if (response.status === 200) {
//           setIsLoading(false);
//           setSuccessMessage("Login effettuato con successo!");
//           setTimeout(() => {
//             setSuccessMessage(null);
//             navigate(`/home`);
//           }, 3000);
//         } else {
//           setIsLoading(false);
//           console.error("Errore nel login");
//           setFailedMessage("Email o password errati!");
//           setTimeout(() => {
//             setFailedMessage(null);
//           }, 3000);
//         }

//         if (response.status === 401) {
//           setIsLoading(false);
//           setFailedMessage("Account ancora non attivo!");
//           setTimeout(() => {
//             setFailedMessage(null);
//           }, 3000);
//         }
//       } catch (e) {
//         setIsLoading(false);
//         console.log(e, "Errore nell'invio dei dati");
//         setFailedMessage("Errore nella richiesta al server");
//         setTimeout(() => {
//           setFailedMessage(null);
//         }, 3000);
//       }
//     }, 2000);
//   };

//   return (
//     <>
//       {successMessage && (
//         <div>
//           <AlertMessage message={successMessage} success={true} />
//         </div>
//       )}

//       {failedMessage && (
//         <div>
//           <AlertMessage message={failedMessage} success={false} />
//         </div>
//       )}

//       {isLoading && (
//         <div className="alert-container">
//           <PuffLoader color="#ec800f" size={200} />
//         </div>
//       )}

//       <div className="new-blog-container container login ">
//         <Form onSubmit={onSubmit}>
//           <Form.Group className="mt-3">
//             <Form.Label className="text-white fw-bold"> Email </Form.Label>
//             <Form.Control
//               type="text"
//               size="lg"
//               name="email"
//               placeholder="Email"
//               onChange={handleInputChange}
//               required
//             />
//           </Form.Group>

//           <Form.Group className="mt-3">
//             <div className="d-flex justify-content-between mb-1">
//               <Form.Label className="text-white fw-bold">Password</Form.Label>
//               <EyeFill
//                 className="ms-2"
//                 role="button"
//                 color="white"
//                 size={30}
//                 onClick={togglePasswordVisibility}
//               />
//             </div>
//             <Form.Control
//               className="custom-input"
//               type={showPassword ? "text" : "password"}
//               size="lg"
//               name="password"
//               placeholder="Password"
//               onChange={handleInputChange}
//               required
//             />
//           </Form.Group>

//           <Form.Group className="d-flex flex-column my-4 justify-content-center">
//             <Button
//               className="my-3 text-center prova fw-bold"
//               type="submit"
//               size="lg"
//             >
//               Login
//             </Button>
//             <Link
//               to="/AddAccount"
//               className="text-white justify-content-center fw-bold"
//             >
//               Non sei ancora registato? Registrati
//             </Link>
//             <Link
//               to="/PassDimenticata"
//               className="text-white justify-content-center fw-bold"
//             >
//               Password dimenticata?
//             </Link>
//           </Form.Group>
//         </Form>
//       </div>
//     </>
//   );
// };

// export default Login;

import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { EyeFill } from "react-bootstrap-icons";
import { PuffLoader } from "react-spinners";
import CircleCorsi from "../assets/CircleAccCorsi.png";
import CircleAppSY from "../assets/CircleAppSY.png";
import CircleSoftwareAutodesk from "../assets/CircleSoftwareAutodesk.png";
import CircleWebinar from "../assets/CircleAccWebinar.png";
import Carousel from "react-bootstrap/Carousel";
import { Col, Container, Row } from "react-bootstrap";
import Banner1 from "../assets/Banner1.png";
import Banner2 from "../assets/Banner2.png";
import Banner3 from "../assets/Banner3.png";
import Banner4 from "../assets/Banner4.png";
import Banner5 from "../assets/Banner5.png";

const Login = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({});
  const [login, setLogin] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setTimeout(async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/login`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(loginData),
          }
        );

        const data = await response.json();

        if (data.token) {
          localStorage.setItem("loggedInUser", JSON.stringify(data.token));
        }
        setLogin(data);

        if (response.status === 200) {
          setIsLoading(false);
          setSuccessMessage("Login effettuato con successo!");
          setTimeout(() => {
            setSuccessMessage(null);
            navigate(`/home`);
          }, 3000);
        } else {
          setIsLoading(false);
          console.error("Errore nel login");
          setFailedMessage("Email o password errati!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }

        if (response.status === 401) {
          setIsLoading(false);
          setFailedMessage("Account ancora non attivo!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e, "Errore nell'invio dei dati");
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    }, 2000);
  };

  /////////////////////////////////////////////
  // const [isFixed, setIsFixed] = useState(true);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollY = window.scrollY;
  //     // Cambia 300 con l'altezza alla quale vuoi che la colonna destra si sblocchi
  //     if (scrollY > 1600) {
  //       setIsFixed(false);
  //     } else {
  //       setIsFixed(true);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [isFixed, setIsFixed] = useState(true);
  const [topPosition, setTopPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const bottomOffset = 172; // Offset dal fondo della pagina
      const triggerHeight = documentHeight - windowHeight - bottomOffset;

      if (scrollY >= triggerHeight) {
        setIsFixed(false);
        setTopPosition(triggerHeight);
      } else {
        setIsFixed(true);
        setTopPosition(0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //////////////////////////////
  // const [isFixed, setIsFixed] = useState(true);
  // const [scrollPosition, setScrollPosition] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollY = window.scrollY;
  //     // Cambia 300 con l'altezza alla quale vuoi che la colonna destra si sblocchi
  //     if (scrollY > 300) {
  //       setIsFixed(false);
  //       setScrollPosition(scrollY - 300); // Mantiene la posizione quando si sblocca
  //     } else {
  //       setIsFixed(true);
  //       setScrollPosition(0);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    // <>
    //   {successMessage && (
    //     <div>
    //       <AlertMessage message={successMessage} success={true} />
    //     </div>
    //   )}

    //   {failedMessage && (
    //     <div>
    //       <AlertMessage message={failedMessage} success={false} />
    //     </div>
    //   )}

    //   {isLoading && (
    //     <div className="alert-container">
    //       <PuffLoader color="#ec800f" size={200} />
    //     </div>
    //   )}

    //   <div className="margin_T90 containerLogin ">
    //     <div className="colonna-sinistra">
    //       <h1>
    //        asd
    //       </h1>
    //     </div>
    //     <div className="colonna-destra">
    //       <div className="text-center">
    //         <h5>Effettua il login</h5>
    //         <h5>per usufruire dei servizi</h5>
    //       </div>
    //       <Form onSubmit={onSubmit}>
    //         <Form.Group className="mt-3">
    //           <Form.Label className="ms-1 fw-bold"> Email </Form.Label>
    //           <Form.Control
    //             type="text"
    //             size="lg"
    //             name="email"
    //             placeholder="Email"
    //             onChange={handleInputChange}
    //             required
    //           />
    //         </Form.Group>

    //         <Form.Group className="mt-3">
    //           <div className="d-flex justify-content-between mb-1">
    //             <Form.Label className="ms-1 fw-bold">Password</Form.Label>
    //             <EyeFill
    //               className="ms-2"
    //               role="button"
    //               color="white"
    //               size={30}
    //               onClick={togglePasswordVisibility}
    //             />
    //           </div>
    //           <Form.Control
    //             className="custom-input"
    //             type={showPassword ? "text" : "password"}
    //             size="lg"
    //             name="password"
    //             placeholder="Password"
    //             onChange={handleInputChange}
    //             required
    //           />
    //         </Form.Group>

    //         <Form.Group className="d-flex flex-column my-4 justify-content-center">
    //           <Button
    //             className="my-3 text-center prova fw-bold text-black"
    //             type="submit"
    //             size="lg"
    //           >
    //             Login
    //           </Button>
    //           <Link
    //             to="/AddAccount"
    //             className="mt-5 text-black justify-content-center fw-bold"
    //           >
    //             Non sei ancora registato? Registrati
    //           </Link>
    //           <Link
    //             to="/PassDimenticata"
    //             className="text-black justify-content-center fw-bold"
    //           >
    //             Password dimenticata?
    //           </Link>
    //         </Form.Group>
    //       </Form>
    //     </div>
    //   </div>
    // </>
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      {/* <div className="margin_T90 containerLogin">
        <div className="colonna-sinistra">
          
        </div>
        <div
          className="colonna-destra"
          style={{
            position: isFixed ? "fixed" : "absolute",
            top: isFixed ? "0" : "300px",
            right: "0",
            width: "30%", // Imposta una larghezza fissa se necessario
            transition: "position 0.3s ease",
          }}
        >
          <div className="text-center">
            <h5>Effettua il login</h5>
            <h5>per usufruire dei servizi</h5>
          </div>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mt-3">
              <Form.Label className="ms-1 fw-bold">Email</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                name="email"
                placeholder="Email"
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <div className="d-flex justify-content-between mb-1">
                <Form.Label className="ms-1 fw-bold">Password</Form.Label>
                <EyeFill
                  className="ms-2"
                  role="button"
                  color="white"
                  size={30}
                  onClick={togglePasswordVisibility}
                />
              </div>
              <Form.Control
                className="custom-input"
                type={showPassword ? "text" : "password"}
                size="lg"
                name="password"
                placeholder="Password"
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="d-flex flex-column my-4 justify-content-center">
              <Button
                className="my-3 text-center prova fw-bold text-black"
                type="submit"
                size="lg"
              >
                Login
              </Button>
              <Link
                to="/AddAccount"
                className="mt-5 text-black justify-content-center fw-bold"
              >
                Non sei ancora registato? Registrati
              </Link>
              <Link
                to="/PassDimenticata"
                className="text-black justify-content-center fw-bold"
              >
                Password dimenticata?
              </Link>
            </Form.Group>
          </Form>
        </div>
      </div> */}
      {/* SCHERMO GRANDE */}
      <div className="margin_T90 containerLogin d-none d-lg-flex d-xxl-flex">
        <div className="colonna-sinistra">
          <Carousel
            pause={"hover"}
            style={{ width: "100%" }}
            variant={"dark"}
            indicators={false}
            className=""
          >
            <Carousel.Item interval={5000}>
              <Link
                to="https://www.symbiotech.it/corsi-autodesk/"
                target="_blank"
              >
                <img
                  src={Banner1}
                  alt=""
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={5000}>
              {" "}
              <Link to="https://www.symbiotech.it/webinar/" target="_blank">
                <img
                  src={Banner2}
                  alt=""
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={5000}>
              <Link to="https://www.symbiotech.it/st-boosters/" target="_blank">
                <img
                  src={Banner3}
                  alt=""
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={5000}>
              {" "}
              <Link
                to="https://www.symbiotech.it/software-autodesk/"
                target="_blank"
              >
                <img
                  src={Banner4}
                  alt=""
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={5000}>
              {" "}
              <Link
                to="https://www.symbiotech.it/workstation-cad/"
                target="_blank"
              >
                <img
                  src={Banner5}
                  alt=""
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Link>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>

          <Container className="text-center my-4">
            <h5>
              Pronto a rivoluzionare il tuo modo di progettare? <br /> Vuoi
              diventare un esperto di Autodesk Inventor e Vault?{" "}
            </h5>
            <h3>"Impara, cresci, innova con Autodesk Inventor e Vault"</h3>
            <p>
              Scopri un mondo di risorse gratuite, una vasta gamma di corsi, per
              aiutarti a padroneggiare questi potenti strumenti. Non perdere
              l'opportunità di sviluppare le tue competenze e di far crescere la
              tua azienda. Iscriviti ora e inizia il tuo percorso!{" "}
            </p>
          </Container>
          <Container fluid className="mb-5">
            {/* <Col
                className="boxImmagini d-flex flex-wrap justify-content-center"
                sm={12}
                xl={12}
              >
                <Link target="_blank" to={"/corsiList"}>
                  <img className="m-3 circleImgLink" src={CircleCorsi} alt="" />
                </Link>
                <Link target="_blank" to={"/webinarList"}>
                  <img
                    className="m-3 circleImgLink"
                    src={CircleWebinar}
                    alt=""
                  />
                </Link>
                <Link
                  target="_blank"
                  to={"https://www.symbiotech.it/symbiotech-app-autodesk/"}
                >
                  <img className="m-3 circleImgLink" src={CircleAppSY} alt="" />
                </Link>
                <Link
                  target="_blank"
                  to={"https://www.symbiotech.it/software-autodesk//"}
                >
                  <img
                    className="m-3 circleImgLink"
                    src={CircleSoftwareAutodesk}
                    alt=""
                  />
                </Link>
              </Col> */}

            <div className="m_">
              <div className="d-flex justify-content-start align-items-center">
                {/* <Link to={"/corsiList"}> */}
                <Link to={"/workInProgressCorsi"}>
                  <img
                    className="m-3 ms-0 me-5 circleImgLink"
                    src={CircleCorsi}
                    alt=""
                  />
                </Link>
                <p>
                  Aumenta la tua produttività con i nostri corsi Autodesk
                  online! <br /> Vuoi migliorare le tue competenze in
                  progettazione CAD e gestione dei dati? <br /> I nostri nuovi
                  corsi online sono la soluzione ideale per te. <br /> Impara a
                  utilizzare al meglio Inventor, AutoCAD, AutoCAD Mechanical,
                  Vault Basic e Vault Professional grazie a un percorso
                  formativo strutturato e coinvolgente.
                </p>
              </div>
            </div>
            <div className="m_">
              <div className="d-flex justify-content-end align-items-center">
                <p className="text-end">
                  "Sei pronto a portare la tua progettazione meccanica al
                  prossimo livello?" <br /> Unisciti a noi per scoprire le
                  ultime novità e funzionalità di Autodesk Inventor. <br /> In
                  questi webinar gratuiti, esploreremo insieme come ottimizzare
                  i tuoi workflow, aumentare la produttività e creare modelli 3D
                  sempre più realistici.
                </p>
                {/* <Link to={"/webinarList"}> */}
                <Link to={"/workInProgressWebinar"}>
                  <img
                    className="m-3 me-0 ms-5 circleImgLink"
                    src={CircleWebinar}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* palla 3 */}
            <div className="m_">
              <div className="d-flex justify-content-start align-items-center">
                <Link
                  target="_blank"
                  to={"https://www.symbiotech.it/symbiotech-app-autodesk/"}
                >
                  <img
                    className="m-3 ms-0 me-5 circleImgLink"
                    src={CircleAppSY}
                    alt=""
                  />
                </Link>
                <p>
                  Potenzia Autodesk Inventor e Vault con le nostre app
                  personalizzate <br /> Siamo orgogliosi di presentare la nostra
                  suite di app, sviluppate per estendere le funzionalità di
                  Inventor e Vault. <br /> Grazie all'integrazione nativa,
                  potrai beneficiare di un ambiente di lavoro più efficiente e
                  produttivo. Scopri come le nostre app possono aiutarti a:{" "}
                  <br />
                  • Automatizzare compiti ripetitivi <br />• Personalizzare
                  l'interfaccia utente <br />• Migliorare la gestione dei dati
                  <br />• Aumentare la collaborazione
                </p>
              </div>
            </div>
            <div className="m_">
              <div className="d-flex justify-content-end align-items-center">
                <p className="text-end w-100">
                  Accelerate la vostra produzione con soluzioni CAD/CAM
                  all'avanguardia. <br /> Aumenta la tua competitività sul
                  mercato del lavoro. <br /> Ottimizza i tuoi processi di
                  progettazione.
                  <br /> Risparmia tempo e denaro.
                </p>
                <Link
                  target="_blank"
                  to={"https://www.symbiotech.it/software-autodesk//"}
                >
                  <img
                    className="m-3 me-0 ms-5 circleImgLink"
                    src={CircleSoftwareAutodesk}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </Container>

          {/* <h1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            metus lectus, pellentesque vitae tempor sed, facilisis molestie
            nisl. Nunc sit amet dui maximus, tempor enim ut, volutpat mauris.
            Integer finibus condimentum purus at ultricies. Integer vel enim
          </h1>
          <h3>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus
            numquam facere, sint repudiandae a aut dignissimos, accusantium,
            officia molestiae saepe maxime! Corporis id suscipit sunt magnam
            porro nobis ratione iure?
          </h3> */}
        </div>
        {/* <div
          className="colonna-destra"
          style={{
            position: isFixed ? "fixed" : "absolute",
            top: isFixed ? "0" : "2000px",
            right: "0",
            width: "30%", // Imposta una larghezza fissa se necessario
            transition: "position 0.3s ease",
          }}
        > */}

        {/* COLONNA LOGIN */}

        <div
          className="colonna-destra"
          style={{
            position: isFixed ? "fixed" : "absolute",
            top: isFixed ? "0" : `${topPosition}px`,
            right: "0",
            width: "30%", // Imposta una larghezza fissa se necessario
            transition: "position 0.3s ease",
          }}
        >
          <div className="contenitore">
            <div className="text-center margin_T150">
              <h5>Effettua il login</h5>
              <h5>per usufruire dei servizi</h5>
            </div>
            <Form onSubmit={onSubmit}>
              <Form.Group className="mt-3">
                <Form.Label className="ms-1 fw-bold">Email</Form.Label>
                <Form.Control
                  type="text"
                  size="lg"
                  name="email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mt-3">
                <div className="d-flex justify-content-between mb-1">
                  <Form.Label className="ms-1 fw-bold">Password</Form.Label>
                  <EyeFill
                    className="ms-2"
                    role="button"
                    color="black"
                    size={25}
                    onClick={togglePasswordVisibility}
                  />
                </div>
                <Form.Control
                  className="custom-input"
                  type={showPassword ? "text" : "password"}
                  size="lg"
                  name="password"
                  placeholder="Password"
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="d-flex flex-column my-4 justify-content-center">
                <Button
                  className="my-3 text-center prova fw-bold text-black"
                  type="submit"
                  size="lg"
                >
                  Login
                </Button>
                <Link
                  to="/AddAccount"
                  className="mt-5 text-black justify-content-center fw-bold"
                >
                  Non sei ancora registato? Registrati
                </Link>
                <Link
                  to="/PassDimenticata"
                  className="text-black justify-content-center fw-bold"
                >
                  Password dimenticata?
                </Link>
              </Form.Group>
            </Form>
          </div>
        </div>
        {/* FINE COLONNA LOGIN */}
      </div>

      {/* SCHERMO PICCOLO */}
      <div className="d-lg-none margin_T90">
        <Container fluid>
          <Row className="d-flex flex-wrap justify-content-center mt-5">
            <Col
              className="boxImmagini d-flex flex-wrap justify-content-center"
              sm={6}
              xl={12}
            >
              <Carousel
                pause={"hover"}
                style={{ width: "100%" }}
                variant={"dark"}
                indicators={false}
                className=""
              >
                <Carousel.Item interval={5000}>
                  <Link
                    to="https://www.symbiotech.it/corsi-autodesk/"
                    target="_blank"
                  >
                    <img
                      src={Banner1}
                      alt=""
                      style={{
                        height: "100px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item interval={5000}>
                  {" "}
                  <Link to="https://www.symbiotech.it/webinar/" target="_blank">
                    <img
                      src={Banner2}
                      alt=""
                      style={{
                        height: "100px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item interval={5000}>
                  <Link
                    to="https://www.symbiotech.it/st-boosters/"
                    target="_blank"
                  >
                    <img
                      src={Banner3}
                      alt=""
                      style={{
                        height: "100px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item interval={5000}>
                  {" "}
                  <Link
                    to="https://www.symbiotech.it/software-autodesk/"
                    target="_blank"
                  >
                    <img
                      src={Banner4}
                      alt=""
                      style={{
                        height: "100px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item interval={5000}>
                  {" "}
                  <Link
                    to="https://www.symbiotech.it/workstation-cad/"
                    target="_blank"
                  >
                    <img
                      src={Banner5}
                      alt=""
                      style={{
                        height: "100px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              </Carousel>

              <Container className="text-center my-4">
                <h5>
                  Pronto a rivoluzionare il tuo modo di progettare? <br /> Vuoi
                  diventare un esperto di Autodesk Inventor e Vault?{" "}
                </h5>
                <h3>"Impara, cresci, innova con Autodesk Inventor e Vault"</h3>
                <p>
                  Scopri un mondo di risorse gratuite, una vasta gamma di corsi,
                  per aiutarti a padroneggiare questi potenti strumenti. Non
                  perdere l'opportunità di sviluppare le tue competenze e di far
                  crescere la tua azienda. Iscriviti ora e inizia il tuo
                  percorso!{" "}
                </p>
              </Container>
              {/* <Link to={"/corsiList"}> */}
              <Link target="_blank" to={"/workInProgressCorsi"}>
                <img className="m-3 circleImgLink" src={CircleCorsi} alt="" />
              </Link>
              <p className="text-center">
                Aumenta la tua produttività con i nostri corsi Autodesk online!{" "}
                <br /> Vuoi migliorare le tue competenze in progettazione CAD e
                gestione dei dati? <br /> I nostri nuovi corsi online sono la
                soluzione ideale per te. <br /> Impara a utilizzare al meglio
                Inventor, AutoCAD, AutoCAD Mechanical, Vault Basic e Vault
                Professional grazie a un percorso formativo strutturato e
                coinvolgente.
              </p>
              {/* <Link to={"/webinarList"}> */}
              <Link to={"/workInProgressWebinar"}>
                <img className="m-3 circleImgLink" src={CircleWebinar} alt="" />
              </Link>
              <p className="text-center">
                "Sei pronto a portare la tua progettazione meccanica al prossimo
                livello?" <br /> Unisciti a noi per scoprire le ultime novità e
                funzionalità di Autodesk Inventor. <br /> In questi webinar
                gratuiti, esploreremo insieme come ottimizzare i tuoi workflow,
                aumentare la produttività e creare modelli 3D sempre più
                realistici.
              </p>
              <Link
                target="_blank"
                to={"https://www.symbiotech.it/symbiotech-app-autodesk/"}
              >
                <img className="m-3 circleImgLink" src={CircleAppSY} alt="" />
              </Link>
              <p className="text-center">
                Potenzia Autodesk Inventor e Vault con le nostre app
                personalizzate <br /> Siamo orgogliosi di presentare la nostra
                suite di app, sviluppate per estendere le funzionalità di
                Inventor e Vault. <br /> Grazie all'integrazione nativa, potrai
                beneficiare di un ambiente di lavoro più efficiente e
                produttivo. Scopri come le nostre app possono aiutarti a: <br />
                • Automatizzare compiti ripetitivi <br />• Personalizzare
                l'interfaccia utente <br />• Migliorare la gestione dei dati
                <br />• Aumentare la collaborazione
              </p>
              <Link
                target="_blank"
                to={"https://www.symbiotech.it/software-autodesk//"}
              >
                <img
                  className="m-3 circleImgLink"
                  src={CircleSoftwareAutodesk}
                  alt=""
                />
              </Link>
              <p className="text-center">
                Accelerate la vostra produzione con soluzioni CAD/CAM
                all'avanguardia. <br /> Aumenta la tua competitività sul mercato
                del lavoro. <br /> Ottimizza i tuoi processi di progettazione.
                <br /> Risparmia tempo e denaro.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
