import React, { useEffect, useState } from "react";
import AxiosClient from "../client/client";
import { Link } from "react-router-dom";
import { CaretDownFill, CaretRightFill, Trash3 } from "react-bootstrap-icons";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

const GestionaleWebinar = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const client = new AxiosClient();
  const [accounts, setAccounts] = useState([]);
  const [webinars, setWebinars] = useState([]);

  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  // GET ACCOUNT
  const getWebinars = async () => {
    try {
      setIsLoadingAccounts(true);
      const response = await client.get(
        `/webinars/byTitle?title=${searchTerm}&page=${currentPage}`
      );
      setWebinars(response);
      setIsLoadingAccounts(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const handleSearchTermChange = (newTerm) => {
    setSearchTerm(newTerm);
    setCurrentPage(1); // Imposta currentPage a 1 quando il termine di ricerca cambia
  };

  useEffect(() => {
    getWebinars();
  }, [currentPage, searchTerm]);

  //console.log(accounts.accounts);

  const deleteWebinar = async (idWebinar) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler eliminare questo corso?"
    );

    if (confirmDelete) {
      setIsLoadingDelete(true);

      setTimeout(async () => {
        try {
          // const response = await client.delete(`course/delete/${idCourse}`);
          // const response = await client.delete(
          //   `courseDelete/cloudUpload/${idCourse}`
          const response = await client.delete(`deleteAllWebinar/${idWebinar}`);
          if (response.statusCode === 200) {
            console.log("Eliminazione webinar avvenuto con successo");
            setIsLoadingDelete(false);
            setSuccessMessage("Webinar eliminato con successo!");
            getWebinars();
            setTimeout(() => {
              setSuccessMessage(null);
            }, 3000);
          } else {
            console.error(
              "Errore durante l'eliminazione del webinar",
              response
            );
            setIsLoadingDelete(false);
            setFailedMessage("Errore durante l'eliminazione del webinar!");
            setTimeout(() => {
              setFailedMessage(null);
            }, 3000);
          }
        } catch (error) {
          console.log(error);
          setIsLoadingDelete(false);
          setFailedMessage("Errore nella richiesta al server");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      }, 2000);
    }
  };

  const handleDeleteClick = (webinarId) => {
    return () => {
      deleteWebinar(webinarId);
    };
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoadingDelete && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      <div className="margin_T150">
        {/* LISTA ACCOUNT */}
        <div className="container mb-5 gestionale-bg">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="me-3 my-0">WEBINAR</h2>

            <input
              className="my_input"
              type="text"
              placeholder="Cerca per titolo"
              value={searchTerm}
              onChange={(e) => handleSearchTermChange(e.target.value)}
            />
          </div>

          {webinars &&
            webinars.webinars
              ?.filter((webinar) =>
                webinar.titolo.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((webinar) => {
                return (
                  <div
                    key={webinar._id}
                    className="d-flex justify-content-between align-items-center my-4 p-account-box-singlePost"
                  >
                    <Link
                      to={`/gestionaleWebinarID/${webinar._id}`}
                      className="clear_link_black"
                    >
                      <div className="d-flex ms-3">
                        <h5>{webinar.titolo}</h5>
                      </div>
                    </Link>

                    <Trash3
                      onClick={handleDeleteClick(webinar._id)}
                      color="red"
                      size={25}
                      role="button"
                      className="mx-3"
                    />
                  </div>
                );
              })}
          <ResponsivePagination
            current={currentPage}
            total={webinars && webinars.totalPages}
            onPageChange={handlePagination}
          />
        </div>
        <div className="mb-5"></div>
      </div>
    </>
  );
};

export default GestionaleWebinar;
