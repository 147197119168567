import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import useSession from "../hooks/useSession";
import AxiosClient from "../client/client";
import "./_textEditor.scss";
import "react-quill/dist/quill.snow.css";
//import { useNavigate } from "react-router-dom";

const AddCourse = () => {
  //const navigate = useNavigate()
  const session = useSession();
  const client = new AxiosClient();
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    titolo: "",
    prezzo: 0,
    ramo1: "",
    ramo2: "",
    ramo3: "",
    ramo4: "",
    cover: null,
  });

  const [file, setFile] = useState(null);

  const onChangeSetFile = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async (cover) => {
    const fileData = new FormData();
    fileData.append("cover", cover);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/course/cloudUpload`,
        {
          method: "POST",
          body: fileData,
        }
      );
      return await response.json();
    } catch (e) {
      console.log(e, "Errore in uploadFile");
      setFailedMessage("Errore upload del file!");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  const [descrizione, setDescrizione] = useState("");

  const handleQuillChange = (value) => {
    setDescrizione(value);
  };

  // console.log(content);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (file) {
      //const rate = parseFloat(formData.rate);

      const uploadCover = await uploadFile(file);

      const data = {
        ...formData,
        //  rate: rate,
        descrizione,
        cover: uploadCover.cover,
      };

      if (!formData.ramo2) {
        delete data.ramo2;
      }
      if (!formData.ramo3) {
        delete data.ramo3;
      }
      if (!formData.ramo4) {
        delete data.ramo4;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/course/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        const responseData = await response.json();

        if (response.status === 201) {
          console.log("Corso creato con successo: ", responseData.payload);
          setFormData({
            titolo: "",
            prezzo: 0,
            ramo1: "",
            ramo2: "",
            ramo3: "",
            ramo4: "",
            cover: null,
          });
          setIsLoading(false);
          setSuccessMessage("Corso creato con successo!");
          setTimeout(() => {
            setSuccessMessage(null);
            //navigate('/home')
          }, 3000);
        } else {
          setIsLoading(false);
          console.error("Errore nella creazione del corso");
          setFailedMessage("Errore nella creazione del corso!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }

        // const emailResponse = await client.post(
        //   "/send-email",
        //   {
        //     to: session.email,
        //     subject: "Nuovo Corso",
        //     text: "Creazione del corso avvenuto con successo",
        //   },
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //   }
        // );
        // console.log(emailResponse);
      } catch (e) {
        console.error("Errore nella richiesta al server:", e);
        setIsLoading(false);
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    }
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      <Container className="new-blog-container margin_T120">
        <h1 className="mb-4">Aggiungi Corso</h1>
        <Form encType="multipart/form-data" onSubmit={onSubmit}>
          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Titolo</Form.Label>
            <Form.Control
              required
              size="lg"
              name="titolo"
              value={formData.titolo}
              onChange={handleInputChange}
              placeholder="Titolo"
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Prezzo</Form.Label>
            <Form.Control
              required
              size="lg"
              name="prezzo"
              value={formData.prezzo}
              onChange={handleInputChange}
              placeholder="prezzo"
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Cover</Form.Label>
            <Form.Control
              required
              size="lg"
              type="file"
              onChange={onChangeSetFile}
              name="cover"
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Ramo 1</Form.Label>
            <Form.Control
              required
              size="lg"
              name="ramo1"
              value={formData.ramo1}
              onChange={handleInputChange}
              placeholder="Ramo 1"
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Ramo 2</Form.Label>
            <Form.Control
              size="lg"
              name="ramo2"
              value={formData.ramo2}
              onChange={handleInputChange}
              placeholder="Ramo 2"
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Ramo 3</Form.Label>
            <Form.Control
              size="lg"
              name="ramo3"
              value={formData.ramo3}
              onChange={handleInputChange}
              placeholder="Ramo 3"
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Ramo 4</Form.Label>
            <Form.Control
              size="lg"
              name="ramo4"
              value={formData.ramo4}
              onChange={handleInputChange}
              placeholder="Ramo 4"
            />
          </Form.Group>

          <Form.Label className="mt-3 fw-bold">Descrizione</Form.Label>
          <ReactQuill
            theme="snow"
            placeholder={"Descrizione..."}
            value={descrizione}
            onChange={handleQuillChange}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ color: [] }, { background: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image", "video"],
                ["clean"],
              ],
            }}
          />
          {/* Utilizza un campo nascosto per inviare il contenuto al server */}
          <input type="hidden" name="descrizione" value={descrizione} />
          {/* </Form.Group> */}

          <Form.Group className="d-flex mt-3 justify-content-end">
            <Button type="reset" size="lg" variant="outline-dark">
              Reset
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="dark"
              style={{
                marginLeft: "1em",
              }}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>

        <h5 style={{ color: "red" }}>!!! ATTENZIONE !!!</h5>
        <span>
          Il titolo deve corrispondere al ramo in cui si desidera sia
          posizionato il livello del webinar.
          <br />
          Ad esempio creo il nodo "C"
          <br />
          Titolo : "C" <br />
          Ramo 1: "A" <br />
          Ramo 2: "B" <br />
          Ramo 3: "C" <br />
          Quindi "C" sarà posizionato al 3' livello e sarà figlio di "B" che sua
          volta è figlio di "A"
          <br />
          <strong>IMPORTANTE RISPETTARE MAIUSCOLE, MINUSCOLE E SPAZI</strong>
        </span>
      </Container>
    </>
  );
};

export default AddCourse;
