import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosClient from "../client/client";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../components/alertMessage/AlertMessage";

const Confirm = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const client = new AxiosClient();

  const [successMessage, setSuccessMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);

  const confirmAccount = async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/confirm/${token}`
      );

      setSuccessMessage("Account attivato con successo!");
      setTimeout(() => {
        setSuccessMessage(null);
        navigate(`/`);
      }, 3000);
    } catch (error) {
      setTimeout(() => {
        setConfirmationMessage(
          "Token di verifica non valido o utente già verificato"
        );
      }, 3000);

      console.error(
        "Token di verifica non valido o utente già verificato, errore: ",
        error
      );
    }
  };

  useEffect(() => {
    confirmAccount();
  }, []);

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      <div className="text-center container component-bg margin_T180">
        <h3 className="fw-bold mb-5">{confirmationMessage}</h3>
      </div>
    </>

    // <div className="margin_T180">
    //   {confirmationMessage !== null ? (
    //     <p className="">{confirmationMessage}</p>
    //   ) : (
    //     <p>Conferma dell'account in corso...</p>
    //   )}
    //   {/* {confirmationMessage && (
    //     <>
    //       <p>{confirmationMessage}</p>
    //     </>
    //   )} */}
    // </div>
  );
};

export default Confirm;

//FUNZIONA
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import AxiosClient from "../client/client";
// import { useNavigate } from "react-router-dom";

// const Confirm = () => {
//   const { token } = useParams();
//   const [confirmationMessage, setConfirmationMessage] = useState(null);
//   const navigate = useNavigate();
//   const client = new AxiosClient();

//   const confirmAccount = async () => {
//     try {
//       const response = await client.get(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/confirm/${token}`
//       );
//       setConfirmationMessage("Account attivo!");
//       //   alert(
//       //     "Registrazione effettuata con successo, ora puoi effettuare il login"
//       //   );
//       //navigate(`/login`);
//     } catch (error) {
//       setConfirmationMessage("Errore durante la conferma dell'account");
//       console.error("Errore durante la conferma dell'account:", error);
//     }
//   };

//   const handleConfirmation = () => {
//     confirmAccount();
//   };

//   return (
//     <div className="margin_T180">
//       {confirmationMessage !== null ? (
//         <p className="">{confirmationMessage}</p>
//       ) : (
//         <>
//           <p>Conferma dell'account in corso...</p>
//           <button onClick={handleConfirmation}>Attiva!</button>
//         </>
//       )}
//     </div>
//     // <div className="margin_T180">
//     //   <p>Conferma dell'account in corso...</p>
//     //   <button onClick={handleConfirmation}>Attiva!</button>
//     // </div>
//   );
// };

// export default Confirm;

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import AxiosClient from "../client/client";
// import { useNavigate } from "react-router-dom";

// const Confirm = () => {
//   const { token } = useParams();
//   const [account, setAccount] = useState([]);
//   const navigate = useNavigate();
//   const client = new AxiosClient();

//   const [confirmationMessage, setConfirmationMessage] = useState(null);
//   const [formData, setFormData] = useState({
//     verificationToken: null,
//     verified: true,
//   });

//   const getAccount = async () => {
//     try {
//       const response = await client.get(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/confirm/${token}`
//       );
//       setAccount(response);
//       //   alert(
//       //     "Registrazione effettuata con successo, ora puoi effettuare il login"
//       //   );
//       //navigate(`/login`);
//     } catch (error) {
//       console.error("Errore durante il recupero dell'account:", error);
//     }
//   };

//   useEffect(() => {
//     getAccount();
//   }, []);

//   const idAccount = account.user?._id;

//   //   const [formData, setFormData] = useState({
//   //     verificationToken: null,
//   //     verified: true,
//   //   });

//   const activeAccount = async () => {
//     try {
//       const response = await client.patch(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/account/update/${idAccount}`,
//         formData
//       );
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleConfirmation = () => {
//     activeAccount();
//   };

//   return (
//     <div className="margin_T180">
//       {confirmationMessage !== null ? (
//         <p className="">{confirmationMessage}</p>
//       ) : (
//         <>
//           <p>Conferma dell'account in corso...</p>
//           <button onClick={handleConfirmation}>Attiva!</button>
//         </>
//       )}
//     </div>
//     // <div className="margin_T180">
//     //   <p>Conferma dell'account in corso...</p>
//     //   <button onClick={handleConfirmation}>Attiva!</button>
//     // </div>
//   );
// };

// export default Confirm;
