import React from "react";
import Attenzione from "../assets/attenzione.png";

const WorkInProgress = () => {
  return (
    <>
      <div className="margin_T150 text-center">
        <h1>WORK IN PROGRESS</h1>
        <img className="mb-4 imgWIP" src={Attenzione} alt="" />
        <h6>Contenuto non ancora disponibile</h6>
        <h4>Stiamo lavorando per voi</h4>
      </div>
    </>
  );
};

export default WorkInProgress;
