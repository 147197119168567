// import React, { useState } from "react";
// import { Button, Container, Form } from "react-bootstrap";
// //import "./styles.css";
// //import MainLayout from "../";
// import AlertMessage from "../components/alertMessage/AlertMessage";
// import { PuffLoader } from "react-spinners";
// //import { useNavigate } from "react-router-dom";

// const AddAccountGestionale = () => {
//   //const navigate = useNavigate()

//   const [successMessage, setSuccessMessage] = useState(null);
//   const [failedMessage, setFailedMessage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);

//   const [formData, setFormData] = useState({
//     nome: "",
//     cognome: "",
//     email: "",
//     password: "",
//     avatar: null,
//     role: "user",
//     confirmPass: "",
//   });

//   const [file, setFile] = useState(null);

//   const onChangeSetFile = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const uploadFile = async (avatar) => {
//     const fileData = new FormData();
//     fileData.append("avatar", avatar);
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/account/cloudUpload`,
//         {
//           method: "POST",
//           body: fileData,
//         }
//       );
//       return await response.json();
//     } catch (e) {
//       console.log(e, "Errore in uploadFile");
//       setFailedMessage("Errore upload del file!");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     if (formData.password !== formData.confirmPass) {
//       setIsLoading(false);
//       setFailedMessage("Le password non corrispondono.");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//       return;
//     }

//     //const formDataToSend = { ...formData };

//     if (file) {
//       const uploadCover = await uploadFile(file);
//       const data = {
//         ...formData,
//         avatar: uploadCover.avatar,
//       };

//       delete data.confirmPass;

//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_SERVER_BASE_URL}/account/create`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//           }
//         );

//         const responseData = await response.json();

//         if (response.status === 201) {
//           console.log("Account creato con successo: ", responseData.payload);
//           setIsLoading(false);
//           setSuccessMessage("Account creato con successo!");
//           setTimeout(() => {
//             setSuccessMessage(null);
//             //navigate('/home')
//           }, 3000);
//         } else {
//           setIsLoading(false);
//           console.error("Errore nella creazione dell'account");
//           setFailedMessage("Errore nella creazione dell'account!");
//           setTimeout(() => {
//             setFailedMessage(null);
//           }, 3000);
//         }
//       } catch (e) {
//         setIsLoading(false);
//         console.error("Errore nella richiesta al server:", e);
//         setFailedMessage("Errore nella richiesta al server");
//         setTimeout(() => {
//           setFailedMessage(null);
//         }, 3000);
//       }
//     }
//   };

//   return (
//     <>
//       {successMessage && (
//         <div>
//           <AlertMessage message={successMessage} success={true} />
//         </div>
//       )}

//       {failedMessage && (
//         <div>
//           <AlertMessage message={failedMessage} success={false} />
//         </div>
//       )}

//       {isLoading && (
//         <div className="alert-container">
//           <PuffLoader color="#ec800f" size={200} />
//         </div>
//       )}

//       <Container className="new-blog-container asd">
//         <h1 className="mb-4">Crea Account</h1>
//         <Form encType="multipart/form-data" onSubmit={onSubmit}>
//           <Form.Group controlId="nome-form" className="mt-3">
//             <Form.Label className="fw-bold">Ruolo</Form.Label>
//             {/* <Form.Control
//               required
//               size="lg"
//               name="nome"
//               value={formData.nome}
//               onChange={handleInputChange}
//               placeholder="Nome"
//             /> */}
//             <select
//               id="blog-category"
//               name="role"
//               className="form-control form-control-lg"
//               onChange={handleInputChange}
//             >
//               <option value="user">User</option>
//               <option value="azienda">Azienda</option>
//               <option value="admin">Admin</option>
//             </select>
//           </Form.Group>

//           <Form.Group controlId="nome-form" className="mt-3">
//             <Form.Label className="fw-bold">Nome</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               name="nome"
//               value={formData.nome}
//               onChange={handleInputChange}
//               placeholder="Nome"
//             />
//           </Form.Group>

//           <Form.Group controlId="cognome-form" className="mt-3">
//             <Form.Label className="fw-bold">Cognome</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               name="cognome"
//               value={formData.cognome}
//               onChange={handleInputChange}
//               placeholder="Cognome"
//             />
//           </Form.Group>

//           <Form.Group controlId="email-form" className="mt-3">
//             <Form.Label className="fw-bold">Email</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               placeholder="Email"
//             ></Form.Control>
//           </Form.Group>

//           <Form.Group controlId="password-form" className="mt-3">
//             <Form.Label className="fw-bold">Password</Form.Label>
//             <Form.Control
//               required
//               type="password"
//               size="lg"
//               name="password"
//               value={formData.password}
//               onChange={handleInputChange}
//               placeholder="Password"
//             />
//           </Form.Group>

//           <Form.Group controlId="confirmPass-form" className="mt-3">
//             <Form.Label className="fw-bold">Password</Form.Label>
//             <Form.Control
//               required
//               type="password"
//               size="lg"
//               name="confirmPass"
//               value={formData.confirmPass}
//               onChange={handleInputChange}
//               placeholder="Password"
//             />
//           </Form.Group>

//           <Form.Group controlId="avatar-form" className="mt-3">
//             <Form.Label className="fw-bold">Avatar</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               type="file"
//               onChange={onChangeSetFile}
//               name="avatar"
//             />
//           </Form.Group>

//           <Form.Group className="d-flex mt-3 justify-content-end">
//             <Button type="reset" size="lg" variant="outline-dark">
//               Reset
//             </Button>
//             <Button
//               type="submit"
//               size="lg"
//               variant="dark"
//               style={{
//                 marginLeft: "1em",
//               }}
//             >
//               {" "}
//               Submit
//             </Button>
//           </Form.Group>
//         </Form>
//       </Container>
//     </>
//   );
// };

// export default AddAccountGestionale;

import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
//import "./styles.css";
//import MainLayout from "../";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
//import { useNavigate } from "react-router-dom";

const AddAccountGestionale = () => {
  //const navigate = useNavigate()

  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    email: "",
    password: "",
    datoFattura: "",
    role: "user",
    confirmPass: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (formData.password !== formData.confirmPass) {
      setIsLoading(false);
      setFailedMessage("Le password non corrispondono.");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
      return;
    }

    //const formDataToSend = { ...formData };

    delete formData.confirmPass;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/account/createFromSymbiotech`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const responseData = await response.json();

      if (response.status === 201) {
        console.log("Account creato con successo: ", responseData.payload);
        setIsLoading(false);
        setSuccessMessage("Account creato con successo!");
        setTimeout(() => {
          setSuccessMessage(null);
          //navigate('/home')
        }, 3000);
      } else {
        setIsLoading(false);
        console.error("Errore nella creazione dell'account");
        setFailedMessage("Errore nella creazione dell'account!");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    } catch (e) {
      setIsLoading(false);
      console.error("Errore nella richiesta al server:", e);
      setFailedMessage("Errore nella richiesta al server");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      <Container className="new-blog-container margin_T120">
        <h1 className="mb-4">Crea Account</h1>
        <Form encType="multipart/form-data" onSubmit={onSubmit}>
          <Form.Group controlId="nome-form" className="mt-3">
            <Form.Label className="fw-bold">Ruolo</Form.Label>

            <select
              id="blog-category"
              name="role"
              className="form-control form-control-lg"
              onChange={handleInputChange}
            >
              <option value="user">User</option>
              <option value="azienda">Azienda</option>
              <option value="admin">Admin</option>
            </select>
          </Form.Group>

          <Form.Group controlId="nome-form" className="mt-3">
            <Form.Label className="fw-bold">Nome</Form.Label>
            <Form.Control
              required
              size="lg"
              name="nome"
              value={formData.nome}
              onChange={handleInputChange}
              placeholder="Nome"
            />
          </Form.Group>

          <Form.Group controlId="cognome-form" className="mt-3">
            <Form.Label className="fw-bold">Cognome</Form.Label>
            <Form.Control
              required
              size="lg"
              name="cognome"
              value={formData.cognome}
              onChange={handleInputChange}
              placeholder="Cognome"
            />
          </Form.Group>

          <Form.Group controlId="email-form" className="mt-3">
            <Form.Label className="fw-bold">Email</Form.Label>
            <Form.Control
              required
              size="lg"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="nome-form" className="mt-3">
            <Form.Label className="fw-bold">Dato Fattura</Form.Label>
            <Form.Control
              required
              size="lg"
              name="datoFattura"
              value={formData.datoFattura}
              onChange={handleInputChange}
              placeholder="p.iva / codice fiscale"
            />
          </Form.Group>

          <Form.Group controlId="password-form" className="mt-3">
            <Form.Label className="fw-bold">Password</Form.Label>
            <Form.Control
              required
              type="password"
              size="lg"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Password"
            />
          </Form.Group>

          <Form.Group controlId="confirmPass-form" className="mt-3">
            <Form.Label className="fw-bold">Conferma Password</Form.Label>
            <Form.Control
              required
              type="password"
              size="lg"
              name="confirmPass"
              value={formData.confirmPass}
              onChange={handleInputChange}
              placeholder="Password"
            />
          </Form.Group>

          <Form.Group className="d-flex mt-3 justify-content-end">
            <Button type="reset" size="lg" variant="outline-dark">
              Reset
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="dark"
              style={{
                marginLeft: "1em",
              }}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};

export default AddAccountGestionale;
