import React from "react";
import Attenzione from "../assets/attenzione.png";
import { Container } from "react-bootstrap";

const WorkInProgressSoftware = () => {
  return (
    <>
      <div className="margin_T150 text-center">
        <h1>WORK IN PROGRESS</h1>
        <img className="mb-4 imgWIP" src={Attenzione} alt="" />
        <h6>Contenuto non ancora disponibile</h6>
        <h4>Stiamo lavorando per voi</h4>
        <Container className="my-5">
          <h2 className="my-4">
            Ottimizza il tuo lavoro con Autodesk e SymbioTech! La nostra nuova
            piattaforma online sarà presto disponibile. Iscriviti alla nostra
            newsletter per essere tra i primi a scoprire tutte le novità e le
            offerte speciali.
          </h2>
          <h4>
            Potenzia Autodesk Inventor e Vault con le nostre app personalizzate
          </h4>
          <p>
            Hai mai desiderato potenziare le funzionalità dei tuoi software
            Autodesk? Le nostre app, sviluppate sulla base dell'esperienza dei
            nostri progettisti e delle tue esigenze, sono la soluzione perfetta!
            Scopri come semplificare e accelerare i tuoi flussi di lavoro con
            strumenti personalizzati e integrati perfettamente in Inventor e
            Vault. <br /> <strong>E non è tutto: </strong>approfitta dei nostri
            pre-ordini per ottenere licenze Autodesk a prezzi vantaggiosi e
            accumulare cashback per attività scontate!
          </p>
        </Container>
      </div>
    </>
  );
};

export default WorkInProgressSoftware;
