// import { jwtDecode } from "jwt-decode";
// import { useNavigate } from "react-router-dom";
// import { useEffect } from "react";
// import { isAuth } from "../middleware/ProtectedRoutes";

// const useSession = () => {

//     const session = isAuth()
//     const decodedSession = session ? jwtDecode(session) : null;

//     const navigate = useNavigate()

//     useEffect(() => {

//         if (!session) {

//             navigate('/', { replace: true })
//         }

//     }, [navigate, session]);

//     return decodedSession;
// };

// export default useSession;

//SEMPRE USATA QUESTA E FUNZIONA SENZA PROBLEMI
import { jwtDecode } from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { isAuth } from "../middleware/ProtectedRoutes";

const useSession = () => {
  const session = isAuth();
  const decodedSession = session ? jwtDecode(session) : null;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isLoginPage = location.pathname === "/login";
    const isAddAccountPage = location.pathname === "/AddAccount";
    const isPassDimenticataPage = location.pathname === "/PassDimenticata";

    const isConfirmPage = location.pathname.startsWith("/confirm/");
    const isConfirmPassPage = location.pathname.startsWith("/changePass/");
    const isCorsiNoLogPage = location.pathname === "/corsiList";
    const isCorsoNoLogIDPage = location.pathname.startsWith("/dettagliCorso/");
    const isWebinarNoLogPage = location.pathname === "/webinarList";
    const isWebinarNoLogIDPage =
      location.pathname.startsWith("/dettagliWebinar/");
    const isWworkInProgressCorsiPage =
      location.pathname === "/workInProgressCorsi";
    const isWworkInProgressWebinarPage =
      location.pathname === "/workInProgressWebinar";
    const isWworkInProgressSoftwarePage =
      location.pathname === "/workInProgressSoftware";
    const isWworkInProgressPage = location.pathname === "/workInProgress";

    // ADMIN
    const isAddAccountGestionalePage =
      location.pathname === "/addAccountGestionale";
    const isAddCoursePage = location.pathname === "/AddCourse";
    const isAddVideoPage = location.pathname === "/AddVideo";
    const isAddWebinarPage = location.pathname === "/addWebinar";
    const isGestionalePage = location.pathname === "/gestionale";
    const isGestionaleCorsiPage = location.pathname === "/gestionaleCorsi";
    const isGestionaleVideoPage = location.pathname === "/gestionaleVideo";
    const isGestionaleWebinarPage = location.pathname === "/gestionaleWebinar";
    const isGestionaleAccountIDPage = location.pathname.startsWith(
      "/gestionaleAccount/"
    );
    const isGestionaleCorsiIDPage = location.pathname.startsWith(
      "/gestionaleCorsoID/"
    );
    const isGestionaleVideoIDPage = location.pathname.startsWith(
      "/gestionaleVideoID/"
    );
    const isGestionaleWebinarIDPage = location.pathname.startsWith(
      "/gestionaleWebinarID/"
    );

    // AZIENDA
    const isAziendaInfoPage = location.pathname.startsWith("/aziendaInfo/");

    //se non sei loggato puoi...vedere queste pagine
    if (
      !session &&
      !isLoginPage &&
      !isAddAccountPage &&
      !isConfirmPage &&
      !isConfirmPassPage &&
      !isPassDimenticataPage &&
      !isCorsiNoLogPage &&
      !isCorsoNoLogIDPage &&
      !isWebinarNoLogPage &&
      !isWebinarNoLogIDPage &&
      !isWworkInProgressPage &&
      !isWworkInProgressCorsiPage &&
      !isWworkInProgressWebinarPage &&
      !isWworkInProgressSoftwarePage
    ) {
      navigate("/", { replace: true });
    } else if (
      //blocco accesso alla pagina dedicata agli admin
      (isAddAccountGestionalePage ||
        isAddVideoPage ||
        isAddCoursePage ||
        isAddWebinarPage ||
        isGestionalePage ||
        isGestionaleCorsiPage ||
        isGestionaleVideoPage ||
        isGestionaleWebinarPage ||
        isGestionaleAccountIDPage ||
        isGestionaleCorsiIDPage ||
        isGestionaleVideoIDPage ||
        isGestionaleWebinarIDPage) &&
      (!session || (session && decodedSession?.role !== "admin"))
    ) {
      // Se l'utente è loggato ma non ha il ruolo "admin", reindirizza altrove
      navigate("/unauthorized", { replace: true });
    } else if (
      //blocco accesso alla pagina dedicata alle aziende
      isAziendaInfoPage &&
      (!session || (session && decodedSession?.role === "user"))
    ) {
      // Se l'utente è loggato ma non ha il ruolo "azienda", reindirizza altrove
      navigate("/unauthorized", { replace: true });
    }
  }, [navigate, session, location.pathname, decodedSession]);

  return decodedSession;
};

export default useSession;

// import { jwtDecode } from "jwt-decode";
// import { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import { isAuth } from "../middleware/ProtectedRoutes";

// const useSession = () => {
//   const [decodedSession, setDecodedSession] = useState(null);
//   const navigate = useNavigate();
//   const location = useLocation();

//   const updateSession = async () => {
//     try {
//       // Effettua una chiamata API per ottenere le informazioni aggiornate della sessione
//       const response = await fetch(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/uploadToken`
//       );
//       const data = await response.json();
//       // Aggiorna lo stato con le informazioni della sessione aggiornate
//       setDecodedSession(data);
//     } catch (error) {
//       console.error("Error updating session:", error);
//     }
//   };

//   useEffect(() => {
//     // Inizializza la sessione quando il componente viene montato
//     const session = isAuth();
//     const decoded = session ? jwtDecode(session) : null;
//     setDecodedSession(decoded);
//   }, []);

//   useEffect(() => {
//     // Effettua un'azione ogni volta che cambia la location
//     const isLoginPage = location.pathname === "/login";
//     const isAddAccountPage = location.pathname === "/AddAccount";
//     const isPassDimenticataPage = location.pathname === "/PassDimenticata";
//     const isAddCoursePage = location.pathname === "/AddCourse";
//     const isAddVideoPage = location.pathname === "/AddVideo";
//     const isGestionalePage = location.pathname === "/gestionale";
//     const isConfirmPage = location.pathname.startsWith("/confirm/");
//     const isConfirmPassPage = location.pathname.startsWith("/changePass/");

//     // Se l'utente non ha eseguito l'accesso e si trova su una pagina diversa dalla pagina di accesso,
//     // reindirizzalo alla pagina di accesso
//     if (
//       !decodedSession &&
//       !isLoginPage &&
//       !isAddAccountPage &&
//       !isConfirmPage &&
//       !isConfirmPassPage &&
//       !isPassDimenticataPage
//     ) {
//       navigate("/", { replace: true });
//     } else if (
//       // Se l'utente è loggato ma non ha il ruolo "admin" e sta tentando di accedere a pagine riservate agli admin,
//       // reindirizzalo altrove
//       (isGestionalePage || isAddVideoPage || isAddCoursePage) &&
//       (!decodedSession || (decodedSession && decodedSession.role !== "admin"))
//     ) {
//       navigate("/unauthorized", { replace: true });
//     }
//   }, [navigate, decodedSession, location.pathname]);

//   return { decodedSession, updateSession };
// };

// export default useSession;
