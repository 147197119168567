// import React, { createContext, useState, useContext } from "react";

// // Crea il contesto del carrello
// const CartContext = createContext();

// // Fornisci un componente Provider per avvolgere gli altri componenti
// export const CartProvider = ({ children }) => {
//   const [numeroElementiCarrello, setNumeroElementiCarrello] = useState(0);

//   return (
//     <CartContext.Provider
//       value={{ numeroElementiCarrello, setNumeroElementiCarrello }}
//     >
//       {children}
//     </CartContext.Provider>
//   );
// };

// // Hook per consumare il contesto
// export const useCart = () => useContext(CartContext);

// CartContext.js

////////////////////////////////////////////////////////////////

// import React, { createContext, useState, useContext, useEffect } from "react";
// import useSession from "../hooks/useSession";

// // Crea il contesto del carrello
// const CartContext = createContext();

// // Fornisci un componente Provider per avvolgere gli altri componenti
// export const CartProvider = ({ children }) => {
//   const session = useSession();
//   const [numeroElementiCarrello, setNumeroElementiCarrello] = useState(
//     session?.carrello?.length || 0
//   );

//   // Aggiorna il numero di elementi nel carrello quando la sessione cambia
//   useEffect(() => {
//     setNumeroElementiCarrello(session?.carrello?.length || 0);
//   }, [session]);

//   return (
//     <CartContext.Provider
//       value={{ numeroElementiCarrello, setNumeroElementiCarrello }}
//     >
//       {children}
//     </CartContext.Provider>
//   );
// };

// // Hook per consumare il contesto
// export const useCart = () => useContext(CartContext);

// CartContext.js

import React, { createContext, useState, useContext, useEffect } from "react";
import useSession from "../hooks/useSession";

// Crea il contesto del carrello
const CartContext = createContext();

// Fornisci un componente Provider per avvolgere gli altri componenti
export const CartProvider = ({ children }) => {
  const session = useSession();
  const [uploadToken, setUploadToken] = useState({});
  const [token, setToken] = useState(null);
  const [booleana, setBooleana] = useState(false);

  //const [asd, setAsd] = useState([]);

  // Funzione per ottenere il token aggiornato
  const getUploadToken = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/uploadToken/${session.id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(uploadToken),
      }
    );

    const data = await response.json();

    if (data.token) {
      localStorage.setItem("loggedInUser", JSON.stringify(data.token));
    }
    setToken(data);
    setBooleana(false);
  };

  useEffect(() => {
    // Effettua la chiamata per ottenere il token solo se la booleana da NodeView è true
    if (booleana) {
      getUploadToken();
    }
  }, [booleana]);

  return (
    <CartContext.Provider value={{ token, booleana, setBooleana }}>
      {children}
    </CartContext.Provider>
  );
};

// Hook per consumare il contesto
export const useCart = () => useContext(CartContext);
