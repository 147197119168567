import React from "react";
import Attenzione from "../assets/attenzione.png";
import { Container } from "react-bootstrap";

const WorkInProgressCorsi = () => {
  return (
    <>
      <div className="margin_T150 text-center">
        <h1>WORK IN PROGRESS</h1>
        <img className="mb-4 imgWIP" src={Attenzione} alt="" />
        <h6>Contenuto non ancora disponibile</h6>
        <h4>Stiamo lavorando per voi</h4>
        <Container className="my-5">
          <h2>Diventa un esperto Autodesk con i nostri nuovi corsi online!</h2>
          <p>
            Nel 2025 lanceremo una piattaforma di e-learning completa e
            intuitiva, dedicata alla formazione sui software Autodesk. Scoprirai
            un percorso di apprendimento personalizzato, suddiviso in moduli
            specifici per i software Inventor, AutoCAD, AutoCAD Mechanical,
            Vault Basic e Vault Professional.{" "}
          </p>
          {/* <h4>Perché scegliere i nostri corsi?</h4>
          <p>
            <strong>Massima flessibilità: </strong> Studia quando e dove vuoi,
            con la massima libertà di organizzare il tuo tempo. <br />{" "}
            <strong>Contenuti aggiornati: </strong>Materiali didattici sempre al
            passo con le ultime novità dei software Autodesk. <br />{" "}
            <strong>Esercitazioni pratiche: </strong> Metti in pratica le tue
            conoscenze con numerosi esercizi e progetti. <br />{" "}
            <strong>Supporto dedicato: </strong> Un team di esperti sarà a tua
            disposizione per rispondere a ogni tuo dubbio. Iscriviti alla nostra
            newsletter per essere informato sulla data di lancio e sulle prime
            promozioni!
          </p> */}
          <div className="text-start mt-5">
            <h4>Perché scegliere i nostri corsi?</h4>
            <p>
              <strong>Massima flessibilità: </strong> Studia quando e dove vuoi,
              con la massima libertà di organizzare il tuo tempo. <br />{" "}
              <strong>Contenuti aggiornati: </strong>Materiali didattici sempre
              al passo con le ultime novità dei software Autodesk. <br />{" "}
              <strong>Esercitazioni pratiche: </strong> Metti in pratica le tue
              conoscenze con numerosi esercizi e progetti. <br />{" "}
              <strong>Supporto dedicato: </strong> Un team di esperti sarà a tua
              disposizione per rispondere a ogni tuo dubbio. Iscriviti alla
              nostra newsletter per essere informato sulla data di lancio e
              sulle prime promozioni!
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default WorkInProgressCorsi;
