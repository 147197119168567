// import React, { useEffect, useState } from "react";
// import AxiosClient from "../client/client";
// import { useParams } from "react-router-dom";
// import useSession from "../hooks/useSession";
// import { Button, Form, Modal } from "react-bootstrap";
// import { jwtDecode } from "jwt-decode";
// import AlertMessage from "../components/alertMessage/AlertMessage";
// import { PuffLoader } from "react-spinners";

// const Me = () => {
//   const { id } = useParams();
//   const session = useSession();
//   const client = new AxiosClient();

//   const [data, setData] = useState([]);
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [imagePreview, setImagePreview] = useState(null);
//   const [uploadToken, setUploadToken] = useState({});
//   const [token, setToken] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [successMessage, setSuccessMessage] = useState(null);
//   const [failedMessage, setFailedMessage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);

//   const [formData, setFormData] = useState({
//     nome: "",
//     cognome: "",
//   });
//   // const [email, setEmail] = useState({
//   //   email: "",
//   // });
//   const [newData, setNewData] = useState({
//     newData: "",
//     confirmPass: "",
//   });
//   const [showModalDue, setShowModalDue] = useState(false);
//   const getData = async () => {
//     const response = await client.get(`/account/${id}`);
//     setData(response);
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   const getUploadToken = async () => {
//     const response = await fetch(
//       `${process.env.REACT_APP_SERVER_BASE_URL}/uploadToken/${id}`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         method: "POST",
//         body: JSON.stringify(uploadToken),
//       }
//     );

//     const data = await response.json();

//     if (data.token) {
//       localStorage.setItem("loggedInUser", JSON.stringify(data.token));
//     }
//     setToken(data);
//   };

//   useEffect(() => {
//     getUploadToken();
//   }, []);

//   const changeAvatar = async (avatar) => {
//     const fileData = new FormData();
//     fileData.append("avatar", avatar);
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/accounts/cloudUpload/${id}`,
//         {
//           method: "PATCH",
//           body: fileData,
//         }
//       );
//       return await response.json();
//     } catch (e) {
//       console.log(e, "Errore in uploadFile");
//       // setFailedMessage("Errore upload del file!");
//       //setTimeout(() => {
//       //  setFailedMessage(null);
//       // }, 3000);
//     }
//   };

//   const onChangeSetFile = (e) => {
//     // setSelectedImage(e.target.files[0]);
//     const file = e.target.files[0];

//     if (file) {
//       setSelectedImage(file);
//       setImagePreview(URL.createObjectURL(file));
//     } else {
//       setSelectedImage(null);
//       setImagePreview(null);
//     }
//   };

//   const onMainButtonClick = async () => {
//     if (selectedImage) {
//       const fileData = new FormData();
//       fileData.append("avatar", selectedImage);

//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_SERVER_BASE_URL}/accounts/cloudUpload/${id}`,
//           {
//             method: "PATCH",
//             body: fileData,
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           console.log("Immagine caricata con successo:", data);

//           // Ora puoi aggiornare l'account con l'avatar appena caricato
//           await client.patch(
//             `/account/update/${id}`,
//             { avatar: data.avatar },
//             {
//               headers: {
//                 "Content-Type": "application/json",
//               },
//             }
//           );

//           console.log("Account modificato con successo.");
//           setSelectedImage(null);
//           getData();
//           getUploadToken();
//         } else {
//           console.error("Errore durante il caricamento dell'immagine");
//         }
//       } catch (error) {
//         console.error("Errore durante la richiesta al server:", error);
//       }
//     } else {
//       console.log("Nessun file selezionato");
//     }
//   };

//   let imgstonzo = selectedImage ? imagePreview : data.account?.avatar;

//   ////////////////////////////////////////////////////////////////////////////////

//   const openEditModal = (accountData) => {
//     console.log(accountData);
//     setFormData({
//       nome: accountData.account?.nome,
//       cognome: accountData.account?.cognome,
//     });
//     setShowModal(true);
//   };

//   const handleModAccount = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     console.log(formData);
//   };

//   const handleEditAccount = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await client.patch(`/account/update/${id}`, formData, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (response.statusCode === 200) {
//         console.log("Dati modificati con successo");
//         setShowModal(false);
//         //     setSuccessMessage("Commento modificato con successo!");
//         //     getPosts()
//         //     setTimeout(() => {
//         //         setSuccessMessage(null);
//         //     }, 3000);
//         getData();
//         getUploadToken();
//       } else {
//         console.error("Errore durante la modifica dei dati");
//         //     setFailedMessage("Errore durante la modifica del commento!");
//         //     setTimeout(() => {
//         //         setFailedMessage(null);
//         //     }, 3000);
//       }
//     } catch (e) {
//       console.error("Errore nella richiesta al server", e);
//       // setFailedMessage("Errore nella richiesta al server");
//       // setTimeout(() => {
//       //     setFailedMessage(null);
//       // }, 3000);
//     }
//   };

//   //////////////

//   // const openEditModalDue = (accountData) => {
//   //   // console.log(accountData);
//   //   setEmail({
//   //     email: accountData.account?.email,
//   //   });
//   //   setShowModalDue(true);
//   // };
//   const openEditModalDue = (accountData) => {
//     // console.log(accountData);
//     setNewData({
//       newData: accountData.newData,
//     });
//     setShowModalDue(true);
//   };

//   // const handleModEmail = (e) => {
//   //   const { name, value } = e.target;
//   //   setEmail({
//   //     ...email,
//   //     [name]: value,
//   //   });
//   //   console.log(email);
//   // };
//   const handleModPassword = (e) => {
//     const { name, value } = e.target;
//     setNewData({
//       ...newData,
//       [name]: value,
//     });
//     console.log(newData);
//   };

//   // const handleEditEmail = async (e) => {
//   //   e.preventDefault();
//   //   try {
//   //     const response = await client.patch(`/changeEmail/${id}`, email, {
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //       },
//   //     });

//   //     if (response.statusCode === 200) {
//   //       console.log("Dati modificati con successo");
//   //       setShowModalDue(false);
//   //       //     setSuccessMessage("Commento modificato con successo!");
//   //       //     getPosts()
//   //       //     setTimeout(() => {
//   //       //         setSuccessMessage(null);
//   //       //     }, 3000);
//   //       //getData();
//   //       //getUploadToken();
//   //     } else {
//   //       console.error("Errore durante la modifica dei dati");
//   //       //     setFailedMessage("Errore durante la modifica del commento!");
//   //       //     setTimeout(() => {
//   //       //         setFailedMessage(null);
//   //       //     }, 3000);
//   //     }
//   //   } catch (e) {
//   //     console.error("Errore nella richiesta al server", e);
//   //     // setFailedMessage("Errore nella richiesta al server");
//   //     // setTimeout(() => {
//   //     //     setFailedMessage(null);
//   //     // }, 3000);
//   //   }
//   // };
//   const handleEditPassword = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     if (newData.newData !== newData.confirmPass) {
//       setIsLoading(false);
//       setFailedMessage("Le password non corrispondono.");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//       return;
//     }

//     const newDataToSend = { ...newData };
//     delete newDataToSend.confirmPass;

//     try {
//       const response = await client.patch(
//         `/changePassword/${id}`,
//         newDataToSend,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.statusCode === 201) {
//         console.log("Dati modificati con successo");
//         setShowModalDue(false);
//         setIsLoading(false);
//         setSuccessMessage("Email inviata con successo!");
//         setTimeout(() => {
//           setSuccessMessage(null);
//           //navigate('/home')
//         }, 3000);
//       } else {
//         console.error("Errore durante la modifica dei dati");
//         setIsLoading(false);
//         setSuccessMessage("Errore durante la modifica dei dati!");
//         setTimeout(() => {
//           setSuccessMessage(null);
//           //navigate('/home')
//         }, 3000);
//       }
//     } catch (e) {
//       setIsLoading(false);
//       console.error("Errore nella richiesta al server", e);
//       setFailedMessage("Errore nella richiesta al server");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//     }
//   };

//   return (
//     <>
//       {successMessage && (
//         <div>
//           <AlertMessage message={successMessage} success={true} />
//         </div>
//       )}

//       {failedMessage && (
//         <div>
//           <AlertMessage message={failedMessage} success={false} />
//         </div>
//       )}

//       {isLoading && (
//         <div className="alert-container">
//           <PuffLoader color="#ec800f" size={200} />
//         </div>
//       )}

//       <div className="d-flex justify-content-center">
//         <div className="margin_T150 text-center">
//           <h1>Dettagli Account</h1>
//           <div className="mt-5 d-flex justify-content-center">
//             <img className="img-avatar me-3" src={imgstonzo} alt="img avatar" />
//             <div className="d-flex flex-column align-items-center justify-content-center">
//               <label htmlFor="avatarInput" className="btn btn-primary mb-2">
//                 Cambia immagine
//               </label>
//               <input
//                 id="avatarInput"
//                 type="file"
//                 accept="image/*"
//                 style={{ display: "none" }}
//                 onChange={onChangeSetFile}
//               />
//               {selectedImage && (
//                 <>
//                   <button
//                     type="button"
//                     className="btn btn-success"
//                     onClick={onMainButtonClick}
//                   >
//                     Salva Modifiche
//                   </button>
//                 </>
//               )}
//             </div>
//           </div>
//           <div className="mt-4">
//             <h4>Nome: {data.account?.nome}</h4>
//             <h4>Cognome: {data.account?.cognome}</h4>
//             <h4>Email: {data.account?.email}</h4>
//           </div>

//           <div>
//             <div className="mt-4">
//               <Button onClick={() => openEditModal(data)}>Modifica dati</Button>
//             </div>
//             {/* <div className="my-2">
//             <Button onClick={() => openEditModalDue(data)}>Cambia email</Button>
//           </div> */}
//             <div className="mt-2">
//               <Button onClick={() => openEditModalDue(data)}>
//                 Resetta password
//               </Button>
//             </div>
//           </div>
//           <div className="my-4">
//             <h4>Corsi acquistati:</h4>
//             <ul>
//               {data.account?.course.map((corso, i) => {
//                 return <li key={i}>{corso}</li>;
//               })}
//             </ul>
//           </div>
//         </div>

//         <Modal show={showModal} onHide={() => setShowModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Modifica Dati Account</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form onSubmit={handleEditAccount}>
//               <Form.Group controlId="editNome">
//                 <Form.Label className="ms-2 fw-bold">Nome</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="nome"
//                   value={formData.nome}
//                   onChange={handleModAccount}
//                   required
//                 />
//               </Form.Group>

//               <Form.Group controlId="editCognome">
//                 <Form.Label className="ms-2 mt-2 fw-bold">Cognome</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="cognome"
//                   value={formData.cognome}
//                   onChange={handleModAccount}
//                   required
//                 />
//               </Form.Group>

//               {/* <Form.Group controlId="editCognome">
//               <Form.Label className="ms-2 mt-2 fw-bold">Azienda</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="azienda"
//                 value={formData.azienda}
//                 onChange={handleModAccount}
//                 required
//               />
//             </Form.Group> */}

//               <Button className="mt-3 btn-success" type="submit">
//                 Salva Modifiche
//               </Button>
//             </Form>
//           </Modal.Body>
//         </Modal>

//         <Modal show={showModalDue} onHide={() => setShowModalDue(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Nuova Password</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form onSubmit={handleEditPassword}>
//               <Form.Group controlId="editPassword">
//                 <Form.Label className="ms-2 fw-bold">Nuova password</Form.Label>
//                 <Form.Control
//                   name="newData"
//                   type="password"
//                   value={newData.newData}
//                   onChange={handleModPassword}
//                   placeholder="Password"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group controlId="confirmPass-form" className="mt-3">
//                 <Form.Label className="fw-bold">Conferma Password</Form.Label>
//                 <Form.Control
//                   required
//                   type="password"
//                   name="confirmPass"
//                   value={newData.confirmPass}
//                   onChange={handleModPassword}
//                   placeholder="Password"
//                 />
//               </Form.Group>

//               <Button className="mt-3 btn-success" type="submit">
//                 Salva Modifiche
//               </Button>
//             </Form>
//           </Modal.Body>
//         </Modal>
//       </div>
//     </>
//   );
// };

// export default Me;

import React, { useEffect, useState } from "react";
import AxiosClient from "../client/client";
import { Await, useParams } from "react-router-dom";
import useSession from "../hooks/useSession";
import { Button, Form, Modal } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import { nanoid } from "nanoid";

const Me = () => {
  const { id } = useParams();
  const session = useSession();
  const client = new AxiosClient();

  const [data, setData] = useState([]);
  const [uploadToken, setUploadToken] = useState({});
  const [token, setToken] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
  });

  const [newData, setNewData] = useState({
    newData: "",
    confirmPass: "",
  });

  const [showModalDue, setShowModalDue] = useState(false);

  const getData = async () => {
    const response = await client.get(`/account/${id}`);
    setData(response);
  };

  useEffect(() => {
    getData();
  }, []);

  const getUploadToken = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/uploadToken/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(uploadToken),
      }
    );

    const data = await response.json();

    if (data.token) {
      localStorage.setItem("loggedInUser", JSON.stringify(data.token));
    }
    setToken(data);
  };

  useEffect(() => {
    getUploadToken();
  }, []);

  ////////////////////////////////////////////////////////////////////////////////

  const openEditModal = (accountData) => {
    console.log(accountData);
    setFormData({
      nome: accountData.account?.nome,
      cognome: accountData.account?.cognome,
    });
    setShowModal(true);
  };

  const handleModAccount = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };

  const handleEditAccount = async (e) => {
    e.preventDefault();
    try {
      const response = await client.patch(`/account/update/${id}`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.statusCode === 200) {
        console.log("Dati modificati con successo");
        setShowModal(false);
        setSuccessMessage("Dati modificati con successo!");
        getData();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 2000);
        getData();
        getUploadToken();
      } else {
        console.error("Errore durante la modifica dei dati");
        setFailedMessage("Errore durante la modifica dei dati!");
        setTimeout(() => {
          setFailedMessage(null);
        }, 2000);
      }
    } catch (e) {
      console.error("Errore nella richiesta al server", e);
      setFailedMessage("Errore nella richiesta al server");
      setTimeout(() => {
        setFailedMessage(null);
      }, 2000);
    }
  };

  //////////////

  const openEditModalDue = (accountData) => {
    setNewData({
      newData: accountData.newData,
    });
    setShowModalDue(true);
  };

  const handleModPassword = (e) => {
    const { name, value } = e.target;
    setNewData({
      ...newData,
      [name]: value,
    });
    console.log(newData);
  };

  const handleEditPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (newData.newData !== newData.confirmPass) {
      setIsLoading(false);
      setFailedMessage("Le password non corrispondono.");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
      return;
    }

    const newDataToSend = { ...newData };
    delete newDataToSend.confirmPass;

    try {
      const response = await client.patch(
        `/changePassword/${id}`,
        newDataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.statusCode === 201) {
        console.log("Dati modificati con successo");
        setShowModalDue(false);
        setIsLoading(false);
        setSuccessMessage("Email inviata con successo!");
        setTimeout(() => {
          setSuccessMessage(null);
          //navigate('/home')
        }, 3000);
      } else {
        console.error("Errore durante la modifica dei dati");
        setIsLoading(false);
        setSuccessMessage("Errore durante la modifica dei dati!");
        setTimeout(() => {
          setSuccessMessage(null);
          //navigate('/home')
        }, 3000);
      }
    } catch (e) {
      setIsLoading(false);
      console.error("Errore nella richiesta al server", e);
      setFailedMessage("Errore nella richiesta al server");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  ///////////////////////////////////////////////////////////
  const [coursesWithIds, setCoursesWithIds] = useState([]);

  // Funzione per ottenere l'ID del corso dato il titolo
  const fetchCourseId = async (courseTitle) => {
    // const response = await fetch(`/corsoIDbyList/${courseTitle}`);
    // const id = await response.json();
    const response = await client.get(`/corsoIDbyList/${courseTitle}`);
    const id = await response;
    return id; // Assumi che l'endpoint restituisca solo l'ID
  };

  useEffect(() => {
    const fetchCourseIds = async () => {
      if (data.account?.course) {
        const promises = data.account.course.map(async (courseTitle) => {
          const id = await fetchCourseId(courseTitle);
          return { title: courseTitle, id };
        });

        const results = await Promise.all(promises);
        setCoursesWithIds(results);
      }
    };

    fetchCourseIds();
  }, [data.account?.course]);

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      <div className="margin_T120 mb-5">
        <h1 className="text-center mb-5">Dettagli Account</h1>
        {/* SCHERMO GRANDE */}
        <div className="d-flex d-none d-sm-flex d-xxl-flex ">
          <div className="d-flex w-100 justify-content-evenly mt-5">
            <div>
              <div className="d-flex align-items-baseline">
                <h5>Nome:</h5>
                <span className="ps-2 textDetAcc"> {data.account?.nome}</span>
              </div>
              <div className="d-flex align-items-baseline">
                <h5>Cognome:</h5>
                <span className="ps-2 textDetAcc">
                  {" "}
                  {data.account?.cognome}
                </span>
              </div>
              <div className="d-flex align-items-baseline">
                <h5>Email:</h5>
                <span className="ps-2 textDetAcc"> {data.account?.email}</span>
              </div>
              <div className="d-flex align-items-baseline">
                <h5>Tipo account:</h5>
                <span className="ps-2 textDetAcc"> {data.account?.role}</span>
              </div>
              <div className="mt-4 ">
                <Button className="me-3" onClick={() => openEditModal(data)}>
                  Modifica dati
                </Button>
                <Button onClick={() => openEditModalDue(data)}>
                  Resetta password
                </Button>
              </div>
            </div>

            {/* CORSI ACQUISTATI */}
            {/* <div>
              <h4 className="mb-3">Corsi acquistati:</h4>

              <div>
                {coursesWithIds.map((course) => {
                  return (
                    <p key={nanoid()} className="m-0">
                      <a
                        className="clear_link_black hover_red"
                        href={`/corsi/${course?.id?.courseId}`}
                      >
                        {course?.title}
                      </a>
                    </p>
                  );
                })}
              </div>
            </div> */}
          </div>
        </div>

        {/* SCHERMO PICCOLO */}
        {/* <div className="margin_T120 text-center d-sm-none"> */}
        {/* <h1>Dettagli Account</h1> */}
        <div className="text-center d-sm-none">
          <div className="mt-4">
            <h4>Nome: {data.account?.nome}</h4>
            <h4>Cognome: {data.account?.cognome}</h4>
            <h4>Email: {data.account?.email}</h4>
            <h4>Tipo account: {data.account?.role}</h4>
          </div>
          <div>
            <div className="mt-4">
              <Button onClick={() => openEditModal(data)}>Modifica dati</Button>
            </div>

            <div className="mt-2">
              <Button onClick={() => openEditModalDue(data)}>
                Resetta password
              </Button>
            </div>
          </div>

          {/* CORSI ACQUISTATI */}
          {/* <div className="my-4">
            <h4 className="mb-3">Corsi acquistati:</h4>

            <div>
              {coursesWithIds.map((course) => {
                return (
                  <p key={nanoid()} className="m-0">
                    <a
                      className="clear_link_black hover_red"
                      href={`/corsi/${course?.id?.courseId}`}
                    >
                      {course?.title}
                    </a>
                  </p>
                );
              })}
            </div>
          </div> */}
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Modifica Dati Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEditAccount}>
              <Form.Group controlId="editNome">
                <Form.Label className="ms-2 fw-bold">Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="nome"
                  value={formData.nome}
                  onChange={handleModAccount}
                  required
                />
              </Form.Group>

              <Form.Group controlId="editCognome">
                <Form.Label className="ms-2 mt-2 fw-bold">Cognome</Form.Label>
                <Form.Control
                  type="text"
                  name="cognome"
                  value={formData.cognome}
                  onChange={handleModAccount}
                  required
                />
              </Form.Group>

              {/* <Form.Group controlId="editCognome">
              <Form.Label className="ms-2 mt-2 fw-bold">Azienda</Form.Label>
              <Form.Control
                type="text"
                name="azienda"
                value={formData.azienda}
                onChange={handleModAccount}
                required
              />
            </Form.Group> */}

              <Button className="mt-3 btn-success" type="submit">
                Salva Modifiche
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showModalDue} onHide={() => setShowModalDue(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Nuova Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEditPassword}>
              <Form.Group controlId="editPassword">
                <Form.Label className="ms-2 fw-bold">Nuova password</Form.Label>
                <Form.Control
                  name="newData"
                  type="password"
                  value={newData.newData}
                  onChange={handleModPassword}
                  placeholder="Password"
                  required
                />
              </Form.Group>
              <Form.Group controlId="confirmPass-form" className="mt-3">
                <Form.Label className="fw-bold">Conferma Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="confirmPass"
                  value={newData.confirmPass}
                  onChange={handleModPassword}
                  placeholder="Password"
                />
              </Form.Group>

              <Button className="mt-3 btn-success" type="submit">
                Salva Modifiche
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Me;
