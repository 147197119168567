// import React, { useEffect, useState } from "react";
// import { Button, Container, Form } from "react-bootstrap";
// import ReactQuill from "react-quill";
// import AxiosClient from "../client/client";
// import AlertMessage from "../components/alertMessage/AlertMessage";
// import { PuffLoader } from "react-spinners";
// import "./_textEditor.scss";
// import "react-quill/dist/quill.snow.css";
// //import { useNavigate } from "react-router-dom";

// const AddVideo = () => {
//   //const navigate = useNavigate()
//   const client = new AxiosClient();
//   const [successMessage, setSuccessMessage] = useState(null);
//   const [failedMessage, setFailedMessage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [corso, setCorso] = useState([]);
//   const [formData, setFormData] = useState({
//     titolo: "",
//     descrizione: "",
//     corso: "",
//     cover: null,
//     video: null,
//   });
//   //asdasdasdasd

//   const [file, setFile] = useState(null);
//   const [video, setVideo] = useState(null);

//   const onChangeSetFile = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const uploadFile = async (cover) => {
//     const fileData = new FormData();
//     fileData.append("cover", cover);
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/videoCover/cloudUpload`,
//         {
//           method: "POST",
//           body: fileData,
//         }
//       );
//       return await response.json();
//     } catch (e) {
//       console.log(e, "Errore in uploadFile");
//       setFailedMessage("Errore upload del file!");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//     }
//   };

//   const onChangeSetVideo = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       setVideo(e.target.files[0]);
//     }
//   };

//   const uploadVideoFile = async (video, setFailedMessage) => {
//     const fileDataVideo = new FormData();
//     fileDataVideo.append("video", video);

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/video/cloudUpload`,
//         {
//           method: "POST",
//           body: fileDataVideo,
//         }
//       );

//       return await response.json();
//     } catch (e) {
//       console.log(e, "Errore in uploadFile");
//       setFailedMessage("Errore upload del file!");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//       return { error: e };
//     }
//   };

//   const [descrizione, setDescrizione] = useState("");

//   const handleQuillChange = (value) => {
//     setDescrizione(value);
//   };

//   // console.log(content);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();

//     setIsLoading(true);

//     if (file && video) {
//       const uploadCover = await uploadFile(file);
//       const uploadVideo = await uploadVideoFile(video);

//       const data = {
//         ...formData,
//         descrizione,
//         cover: uploadCover.cover,
//         video: uploadVideo.video,
//       };

//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_SERVER_BASE_URL}/video/create`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//           }
//         );

//         const responseData = await response.json();

//         if (response.status === 201) {
//           console.log("Corso creato con successo: ", responseData.payload);
//           setFormData({
//             titolo: "",
//             descrizione: "",
//             corso: "",
//             cover: null,
//             video: null,
//           });
//           setIsLoading(false);
//           setSuccessMessage("Corso creato con successo!");
//           setTimeout(() => {
//             setSuccessMessage(null);
//             //navigate('/home')
//           }, 3000);
//         } else {
//           setIsLoading(false);
//           console.error("Errore nella creazione del corso");
//           setFailedMessage("Errore nella creazione del corso!");
//           setTimeout(() => {
//             setFailedMessage(null);
//           }, 3000);
//         }

//         // const emailResponse = await client.post("/send-email", {
//         //     to: session.email,
//         //     subject: 'Nuovo Game',
//         //     text: 'Creazione del Game avvenuta con successo'
//         // }, {
//         //     headers: {
//         //         "Content-Type": "application/json",
//         //     },
//         // });
//         // console.log(emailResponse);
//       } catch (e) {
//         console.error("Errore nella richiesta al server:", e);
//         setIsLoading(false);
//         setFailedMessage("Errore nella richiesta al server");
//         setTimeout(() => {
//           setFailedMessage(null);
//         }, 3000);
//       }
//     } else {
//       setIsLoading(false);
//       setFailedMessage("Errore: File video non definito");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//     }
//   };

//   const getCourse = async () => {
//     try {
//       const response = await client.get("/courses");
//       setCorso(response);
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   useEffect(() => {
//     getCourse();
//   }, []);

//   console.log(corso.courses);
//   console.log(file);

//   return (
//     <>
//       {successMessage && (
//         <div>
//           <AlertMessage message={successMessage} success={true} />
//         </div>
//       )}

//       {failedMessage && (
//         <div>
//           <AlertMessage message={failedMessage} success={false} />
//         </div>
//       )}

//       {isLoading && (
//         <div className="alert-container">
//           <PuffLoader color="#ec800f" />
//         </div>
//       )}

//       <Container className="new-blog-container asd">
//         <h1 className="mb-4">Aggiungi Video</h1>
//         <Form encType="multipart/form-data" onSubmit={onSubmit}>
//           <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">ID corso</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               name="corso"
//               value={formData.corso}
//               onChange={handleInputChange}
//               placeholder="Corso"
//             />
//           </Form.Group>

//           <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">Titolo</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               name="titolo"
//               value={formData.titolo}
//               onChange={handleInputChange}
//               placeholder="Titolo"
//             />
//           </Form.Group>

//           <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">Cover</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               type="file"
//               onChange={onChangeSetFile}
//               name="cover"
//             />
//           </Form.Group>

//           <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">Video</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               type="file"
//               onChange={onChangeSetVideo}
//               name="video"
//             />
//           </Form.Group>

//           <Form.Label className="mt-3 fw-bold">Descrizione</Form.Label>
//           <ReactQuill
//             theme="snow"
//             placeholder={"Descrizione..."}
//             value={descrizione}
//             onChange={handleQuillChange}
//             modules={{
//               toolbar: [
//                 [{ header: [1, 2, false] }],
//                 ["bold", "italic", "underline", "strike", "blockquote"],
//                 [{ color: [] }, { background: [] }],
//                 [{ list: "ordered" }, { list: "bullet" }],
//                 ["link", "image", "video"],
//                 ["clean"],
//               ],
//             }}
//           />
//           {/* Utilizza un campo nascosto per inviare il contenuto al server */}
//           <input type="hidden" name="descrizione" value={descrizione} />
//           {/* </Form.Group> */}

//           <Form.Group className="d-flex mt-3 justify-content-end">
//             <Button type="reset" size="lg" variant="outline-dark">
//               Reset
//             </Button>
//             <Button
//               type="submit"
//               size="lg"
//               variant="dark"
//               style={{
//                 marginLeft: "1em",
//               }}
//             >
//               Submit
//             </Button>
//           </Form.Group>
//         </Form>
//       </Container>
//     </>
//   );
// };

// export default AddVideo;

// // FUNZIONANTE CON UPLOAD VIDEO SU CLOUDINARY / PRIMA DI SDOPPIARE CORSI WEBINAR
// import React, { useEffect, useState } from "react";
// import { Button, Container, Form } from "react-bootstrap";
// import ReactQuill from "react-quill";
// import AxiosClient from "../client/client";
// import AlertMessage from "../components/alertMessage/AlertMessage";
// import { PuffLoader } from "react-spinners";
// import "./_textEditor.scss";
// import "react-quill/dist/quill.snow.css";

// //import { useNavigate } from "react-router-dom";

// const AddVideo = () => {
//   //const navigate = useNavigate()
//   const client = new AxiosClient();
//   const [successMessage, setSuccessMessage] = useState(null);
//   const [failedMessage, setFailedMessage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [corso, setCorso] = useState([]);
//   const [formData, setFormData] = useState({
//     titolo: "",
//     descrizione: "",
//     descrizioneBreve: "",
//     corso: "",
//     cover: null,
//     video: null,
//   });
//   //asdasdasdasd

//   const [file, setFile] = useState(null);
//   const [video, setVideo] = useState(null);

//   const onChangeSetFile = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const uploadFile = async (cover) => {
//     const fileData = new FormData();
//     fileData.append("cover", cover);
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/videoCover/cloudUpload`,
//         {
//           method: "POST",
//           body: fileData,
//         }
//       );
//       return await response.json();
//     } catch (e) {
//       console.log(e, "Errore in uploadFile");
//       setFailedMessage("Errore upload del file!");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//     }
//   };

//   const onChangeSetVideo = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       setVideo(e.target.files[0]);
//     }
//   };

//   const uploadVideoFile = async (video, setFailedMessage) => {
//     const fileDataVideo = new FormData();
//     fileDataVideo.append("video", video);

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_SERVER_BASE_URL}/video/cloudUpload`,
//         {
//           method: "POST",
//           body: fileDataVideo,
//         }
//       );

//       return await response.json();
//     } catch (e) {
//       console.log(e, "Errore in uploadFile");
//       setFailedMessage("Errore upload del file!");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//       return { error: e };
//     }
//   };

//   const [descrizione, setDescrizione] = useState("");

//   const handleQuillChange = (value) => {
//     setDescrizione(value);
//   };

//   // console.log(content);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();

//     setIsLoading(true);

//     if (file && video) {
//       const uploadCover = await uploadFile(file);
//       const uploadVideo = await uploadVideoFile(video);

//       const data = {
//         ...formData,
//         descrizione,
//         cover: uploadCover.cover,
//         video: uploadVideo.video,
//       };

//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_SERVER_BASE_URL}/video/create`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//           }
//         );

//         const responseData = await response.json();

//         if (response.status === 201) {
//           console.log("Corso creato con successo: ", responseData.payload);
//           setFormData({
//             titolo: "",
//             descrizione: "",
//             descrizioneBreve: "",
//             corso: "",
//             cover: null,
//             video: null,
//           });
//           setIsLoading(false);
//           setSuccessMessage("Video aggiunto con successo!");
//           setTimeout(() => {
//             setSuccessMessage(null);
//             //navigate('/home')
//           }, 3000);
//         } else {
//           setIsLoading(false);
//           console.error("Errore nell'inserimento del video");
//           setFailedMessage("Errore nell'inserimento del video!");
//           setTimeout(() => {
//             setFailedMessage(null);
//           }, 3000);
//         }

//         // const emailResponse = await client.post("/send-email", {
//         //     to: session.email,
//         //     subject: 'Nuovo Game',
//         //     text: 'Creazione del Game avvenuta con successo'
//         // }, {
//         //     headers: {
//         //         "Content-Type": "application/json",
//         //     },
//         // });
//         // console.log(emailResponse);
//       } catch (e) {
//         console.error("Errore nella richiesta al server:", e);
//         setIsLoading(false);
//         setFailedMessage("Errore nella richiesta al server");
//         setTimeout(() => {
//           setFailedMessage(null);
//         }, 3000);
//       }
//     } else {
//       setIsLoading(false);
//       setFailedMessage("Errore: File video non definito");
//       setTimeout(() => {
//         setFailedMessage(null);
//       }, 3000);
//     }
//   };

//   const getCourse = async () => {
//     try {
//       const response = await client.get("/courses");
//       setCorso(response);
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   useEffect(() => {
//     getCourse();
//   }, []);

//   //console.log(corso.courses);
//   //console.log(file);
//   console.log(formData.corso);
//   return (
//     <>
//       {successMessage && (
//         <div>
//           <AlertMessage message={successMessage} success={true} />
//         </div>
//       )}

//       {failedMessage && (
//         <div>
//           <AlertMessage message={failedMessage} success={false} />
//         </div>
//       )}

//       {isLoading && (
//         <div className="alert-container">
//           <PuffLoader color="#ec800f" />
//         </div>
//       )}

//       <Container className="new-blog-container asd">
//         <h1 className="mb-4">Aggiungi Video</h1>
//         <Form encType="multipart/form-data" onSubmit={onSubmit}>
//           <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">Corso</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               as="select"
//               name="corso"
//               value={formData.corso}
//               onChange={handleInputChange}
//             >
//               <option value="" disabled hidden>
//                 Seleziona un corso
//               </option>
//               {
//                 //logica per generare le opzioni in base ai corsi creati
//                 //selezionando un titolo si seleziona l'id del corso corrispondente
//                 corso.courses &&
//                   corso.courses.map((course) => (
//                     <option key={course._id} value={course._id}>
//                       {course.titolo}
//                     </option>
//                   ))
//               }
//             </Form.Control>
//           </Form.Group>

//           {/* <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">ID corso</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               name="corso"
//               value={formData.corso}
//               onChange={handleInputChange}
//               placeholder="Corso"
//             />
//           </Form.Group> */}

//           <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">Titolo</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               name="titolo"
//               value={formData.titolo}
//               onChange={handleInputChange}
//               placeholder="Titolo"
//             />
//           </Form.Group>

//           <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">Descrizione breve</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               name="descrizioneBreve"
//               value={formData.descrizioneBreve}
//               onChange={handleInputChange}
//               placeholder="Descrizione"
//             />
//           </Form.Group>

//           <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">Cover</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               type="file"
//               onChange={onChangeSetFile}
//               name="cover"
//             />
//           </Form.Group>

//           <Form.Group className="mt-3">
//             <Form.Label className="fw-bold">Video</Form.Label>
//             <Form.Control
//               required
//               size="lg"
//               type="file"
//               onChange={onChangeSetVideo}
//               name="video"
//             />
//           </Form.Group>

//           <Form.Label className="mt-3 fw-bold">Descrizione</Form.Label>
//           <ReactQuill
//             theme="snow"
//             placeholder={"Descrizione..."}
//             value={descrizione}
//             onChange={handleQuillChange}
//             modules={{
//               toolbar: [
//                 [{ header: [1, 2, false] }],
//                 ["bold", "italic", "underline", "strike", "blockquote"],
//                 [{ color: [] }, { background: [] }],
//                 [{ list: "ordered" }, { list: "bullet" }],
//                 ["link", "image", "video"],
//                 ["clean"],
//               ],
//             }}
//           />
//           {/* Utilizza un campo nascosto per inviare il contenuto al server */}
//           <input type="hidden" name="descrizione" value={descrizione} />
//           {/* </Form.Group> */}

//           <Form.Group className="d-flex mt-3 justify-content-end">
//             <Button type="reset" size="lg" variant="outline-dark">
//               Reset
//             </Button>
//             <Button
//               type="submit"
//               size="lg"
//               variant="dark"
//               style={{
//                 marginLeft: "1em",
//               }}
//             >
//               Submit
//             </Button>
//           </Form.Group>
//         </Form>
//       </Container>
//     </>
//   );
// };

// export default AddVideo;

// FUNZIONANTE CON UPLOAD VIDEO SU CLOUDINARY
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import AxiosClient from "../client/client";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import "./_textEditor.scss";
import "react-quill/dist/quill.snow.css";

//import { useNavigate } from "react-router-dom";

const AddVideo = () => {
  //const navigate = useNavigate()
  const client = new AxiosClient();
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [corso, setCorso] = useState([]);
  const [formData, setFormData] = useState({
    titolo: "",
    descrizione: "",
    descrizioneBreve: "",
    corso: "",
    cover: null,
    video: null,
  });
  //asdasdasdasd

  const [file, setFile] = useState(null);
  const [video, setVideo] = useState(null);

  const onChangeSetFile = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async (cover) => {
    const fileData = new FormData();
    fileData.append("cover", cover);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/videoCover/cloudUpload`,
        {
          method: "POST",
          body: fileData,
        }
      );
      return await response.json();
    } catch (e) {
      console.log(e, "Errore in uploadFile");
      setFailedMessage("Errore upload del file!");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  const onChangeSetVideo = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setVideo(e.target.files[0]);
    }
  };

  const uploadVideoFile = async (video, setFailedMessage) => {
    const fileDataVideo = new FormData();
    fileDataVideo.append("video", video);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/video/cloudUpload`,
        {
          method: "POST",
          body: fileDataVideo,
        }
      );

      return await response.json();
    } catch (e) {
      console.log(e, "Errore in uploadFile");
      setFailedMessage("Errore upload del file!");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
      return { error: e };
    }
  };

  const [descrizione, setDescrizione] = useState("");

  const handleQuillChange = (value) => {
    setDescrizione(value);
  };

  // console.log(content);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (file && video) {
      const uploadCover = await uploadFile(file);
      const uploadVideo = await uploadVideoFile(video);

      const data = {
        ...formData,
        descrizione,
        cover: uploadCover.cover,
        video: uploadVideo.video,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/video/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        const responseData = await response.json();

        if (response.status === 201) {
          console.log("Corso creato con successo: ", responseData.payload);
          setFormData({
            titolo: "",
            descrizione: "",
            descrizioneBreve: "",
            corso: "",
            cover: null,
            video: null,
          });
          setIsLoading(false);
          setSuccessMessage("Video aggiunto con successo!");
          setTimeout(() => {
            setSuccessMessage(null);
            //navigate('/home')
          }, 3000);
        } else {
          setIsLoading(false);
          console.error("Errore nell'inserimento del video");
          setFailedMessage("Errore nell'inserimento del video!");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }

        // const emailResponse = await client.post("/send-email", {
        //     to: session.email,
        //     subject: 'Nuovo Game',
        //     text: 'Creazione del Game avvenuta con successo'
        // }, {
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // });
        // console.log(emailResponse);
      } catch (e) {
        console.error("Errore nella richiesta al server:", e);
        setIsLoading(false);
        setFailedMessage("Errore nella richiesta al server");
        setTimeout(() => {
          setFailedMessage(null);
        }, 3000);
      }
    } else {
      setIsLoading(false);
      setFailedMessage("Errore: File video non definito");
      setTimeout(() => {
        setFailedMessage(null);
      }, 3000);
    }
  };

  const [valore, setValore] = useState("webinars");

  const handleInputChangeValore = (event) => {
    setValore(event.target.value);
  };

  let scelta = "courses" || "webinars";

  const getCourse = async () => {
    try {
      const response = await client.get(`/${valore}`);
      setCorso(response);
    } catch (e) {
      console.log(e);
    }
  };

  // const getCourse = async () => {
  //   try {
  //     const response = await client.get("/courses");
  //     setCorso(response);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    getCourse();
  }, [valore]);

  console.log(valore);
  //console.log(file);
  console.log(formData.corso);
  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" />
        </div>
      )}

      <Container className="new-blog-container margin_T120">
        <h1 className="mb-4">Aggiungi Video</h1>
        <Form encType="multipart/form-data" onSubmit={onSubmit}>
          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Categoria</Form.Label>
            <Form.Control
              required
              size="lg"
              as="select"
              name="valore"
              value={valore}
              onChange={handleInputChangeValore}
            >
              <option value="webinars">Webinar</option>
              <option value="courses">Corsi</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Corso</Form.Label>
            <Form.Control
              required
              size="lg"
              as="select"
              name="corso"
              value={formData.corso}
              onChange={handleInputChange}
            >
              <option value="" disabled hidden>
                Seleziona un corso
              </option>
              {
                //logica per generare le opzioni in base ai corsi creati
                //selezionando un titolo si seleziona l'id del corso corrispondente
                corso.courses &&
                  corso.courses.map((course) => (
                    <option key={course._id} value={course._id}>
                      {course.titolo}
                    </option>
                  ))
              }
            </Form.Control>
          </Form.Group>

          {/* <Form.Group className="mt-3">
            <Form.Label className="fw-bold">ID corso</Form.Label>
            <Form.Control
              required
              size="lg"
              name="corso"
              value={formData.corso}
              onChange={handleInputChange}
              placeholder="Corso"
            />
          </Form.Group> */}

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Titolo</Form.Label>
            <Form.Control
              required
              size="lg"
              name="titolo"
              value={formData.titolo}
              onChange={handleInputChange}
              placeholder="Titolo"
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Descrizione breve</Form.Label>
            <Form.Control
              required
              size="lg"
              name="descrizioneBreve"
              value={formData.descrizioneBreve}
              onChange={handleInputChange}
              placeholder="Descrizione"
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Cover</Form.Label>
            <Form.Control
              required
              size="lg"
              type="file"
              onChange={onChangeSetFile}
              name="cover"
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="fw-bold">Video</Form.Label>
            <Form.Control
              required
              size="lg"
              type="file"
              onChange={onChangeSetVideo}
              name="video"
            />
          </Form.Group>

          <Form.Label className="mt-3 fw-bold">Descrizione</Form.Label>
          <ReactQuill
            theme="snow"
            placeholder={"Descrizione..."}
            value={descrizione}
            onChange={handleQuillChange}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ color: [] }, { background: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image", "video"],
                ["clean"],
              ],
            }}
          />
          {/* Utilizza un campo nascosto per inviare il contenuto al server */}
          <input type="hidden" name="descrizione" value={descrizione} />
          {/* </Form.Group> */}

          <Form.Group className="d-flex mt-3 justify-content-end">
            <Button type="reset" size="lg" variant="outline-dark">
              Reset
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="dark"
              style={{
                marginLeft: "1em",
              }}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};

export default AddVideo;
