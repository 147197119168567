import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosClient from "../client/client";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../components/alertMessage/AlertMessage";

const ConfirmPass = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const client = new AxiosClient();

  const [successMessage, setSuccessMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);

  const confirmAccount = async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/changePass/${token}`
      );

      setSuccessMessage("Password modificata con successo!");
      setTimeout(() => {
        setSuccessMessage(null);
        navigate(`/`);
      }, 3000);
    } catch (error) {
      setTimeout(() => {
        setConfirmationMessage("Token di modifica non valido");
      }, 3000);

      console.error("Token di modifica non valido, errore: ", error);
    }
  };

  useEffect(() => {
    confirmAccount();
  }, []);

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      <div className="text-center container component-bg margin_T180">
        <h3 className="fw-bold mb-5">{confirmationMessage}</h3>
      </div>
    </>

    // <div className="margin_T180">
    //   {confirmationMessage !== null ? (
    //     <p className="">{confirmationMessage}</p>
    //   ) : (
    //     <p>Conferma dell'account in corso...</p>
    //   )}
    //   {/* {confirmationMessage && (
    //     <>
    //       <p>{confirmationMessage}</p>
    //     </>
    //   )} */}
    // </div>
  );
};

export default ConfirmPass;
