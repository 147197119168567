import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import AxiosClient from "../client/client";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import useSession from "../hooks/useSession";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";

const AziendaInfo = () => {
  const { id } = useParams();
  const client = new AxiosClient();
  const session = useSession();

  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ricarica, setRicarica] = useState(false);
  const [data, setData] = useState([]);
  const [dataCalcolo, setDataCalcolo] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get(`/account/${id}`);
        setData(response);
        if (response.account && response.account.storico) {
          // Mappa lo storico per popolare rowData
          const mappedData = response.account.storico.map((entry) => ({
            data: entry.data,
            nrFattura: entry.nrFattura,
            descrizione: entry.descrizione,
            imponibile: `${entry.imponibile} €`,
            cashbackMaturato: `${entry.cashbackPositivo} €`,
            cashbackSpeso: `${entry.cashbackNegativo} €`,
          }));
          setRowData(mappedData);
          setRicarica(false);
        } else {
          console.error("Storico non trovato nell'account.");
        }
      } catch (error) {
        console.error("Errore nel caricamento dei dati dell'account:", error);
      }
    };

    fetchData();
  }, [ricarica]);

  // const [rowData, setRowData] = useState([
  //   {
  //     data: "08/08/2024",
  //     nrFattura: "1",
  //     descrizione: "Asd",
  //     imponibile: 123,
  //     cashback: "10",
  //   },
  //   {
  //     data: "23/08/2024",
  //     nrFattura: "2",
  //     descrizione: "Qwe",
  //     imponibile: 456,
  //     cashback: "20",
  //   },
  //   {
  //     data: "30/08/2024",
  //     nrFattura: "3",
  //     descrizione: "Tre",
  //     imponibile: 789,
  //     cashback: "30",
  //   },
  // ]);

  const [rowData, setRowData] = useState([]);

  const defaultColDef = useMemo(() => ({ flex: 1 }));

  const [colDefs, setColDefs] = useState([
    { field: "data" },
    { field: "nrFattura" },
    { field: "descrizione" },
    { field: "imponibile" },
    { field: "cashbackMaturato" },
    { field: "cashbackSpeso" },
  ]);

  // const fetchData = async () => {
  //   try {
  //     const response = await client.get(`/account/${id}`);
  //     setData(response);
  //     if (data.storico) {
  //       // Mappa lo storico per popolare rowData
  //       const mappedData = data.storico?.map((entry) => ({
  //         data: entry.data,
  //         nrFattura: entry.nrFattura,
  //         descrizione: entry.descrizione,
  //         imponibile: entry.imponibile,
  //         cashback: entry.cashback,
  //       }));
  //       setRowData(mappedData);
  //     } else {
  //       console.error("Storico non trovato nell'account.");
  //     }
  //   } catch (error) {
  //     console.error("Errore nel caricamento dei dati dell'account:", error);
  //   }
  // };

  // // Funzione per caricare i dati dall'API
  // useEffect(() => {
  //   fetchData();
  // }, []);

  ///////////////////////////////

  const [formData, setFormData] = useState({
    data: "",
    nrFattura: "",
    descrizione: "",
    imponibile: 0,
    cashbackPositivo: 0,
    cashbackNegativo: 0,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [totCashback, setTotCashback] = useState({
    totCashback: 0,
  });

  const calcoloCashabackTotale = () => {
    let risultato = 0;
    data.account?.storico.map((data) => {
      let positivo = data.cashbackPositivo;
      let negativo = data.cashbackNegativo;

      if (positivo != 0) {
        risultato += positivo;
      }
      if (negativo != 0) {
        risultato -= negativo;
      }
    });
    return risultato;
  };

  // const dataPerCalcolo = async () => {
  //   try {
  //     const response = await client.get(`/account/${id}`);
  //     setDataCalcolo(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   dataPerCalcolo();
  // }, []);

  // const calcoloCashabackTotale = async () => {
  //   let risultato = 0;
  //   dataCalcolo.account?.storico.map((data) => {
  //     let positivo = data.cashbackPositivo;
  //     let negativo = data.cashbackNegativo;

  //     if (positivo != 0) {
  //       risultato += positivo;
  //     }
  //     if (negativo != 0) {
  //       risultato -= negativo;
  //     }
  //   });
  //   return risultato;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Fai la prima chiamata API per aggiornare lo storico sul server
      const response = await client.patch(
        `/account/addStorico/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Reset del form e stato locale
      setRicarica(true);
      setFormData({
        data: "",
        nrFattura: "",
        descrizione: "",
        imponibile: 0,
        cashbackPositivo: 0,
        cashbackNegativo: 0,
      });

      // // Ora che hai i dati aggiornati, ricalcola il cashback totale
      // const risultatoCashbackTot = calcoloCashabackTotale(updatedData);
      // console.log("Cashback totale calcolato:", risultatoCashbackTot);

      // // Imposta il nuovo valore di totCashback
      // setTotCashback({
      //   totCashback: risultatoCashbackTot,
      // });

      // // Invia il cashback aggiornato al server
      // const responseUpdate = await client.patch(
      //   `/account/updateTotCashback/${id}`,
      //   { totCashback: risultatoCashbackTot },
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );
    } catch (error) {
      console.error("Errore durante l'aggiornamento:", error);
      alert("Errore durante l'aggiornamento dell'account.");
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await client.patch(
  //       `/account/addStorico/${id}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     setRicarica(true);
  //     // setFormData({
  //     //   data: "",
  //     //   nrFattura: "",
  //     //   descrizione: "",
  //     //   imponibile: 0,
  //     //   cashbackPositivo: 0,
  //     //   cashbackNegativo: 0,
  //     // });
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Error updating account.");
  //   }
  //   try {
  //     let risultatoCashbackTot = calcoloCashabackTotale();
  //     let asdasdsad;
  //     ///////
  //     if (formData.cashbackPositivo != 0) {
  //       asdasdsad = risultatoCashbackTot += formData.cashbackPositivo;
  //     }
  //     ///////

  //     //console.log(risultatoCashbackTot);

  //     // setTotCashback({
  //     //   totCashback: risultatoCashbackTot,
  //     // });
  //     // console.log(totCashback);

  //     // const response = await client.patch(
  //     //   `/account/updateTotCashback/${id}`,
  //     //   totCashback,
  //     //   {
  //     //     headers: {
  //     //       "Content-Type": "application/json",
  //     //     },
  //     //   }
  //     // );
  //     setTotCashback({
  //       totCashback: asdasdsad,
  //     });
  //     console.log(asdasdsad); // logga il nuovo valore

  //     // Usa direttamente risultatoCashbackTot nella chiamata API
  //     const response = await client.patch(
  //       `/account/updateTotCashback/${id}`,
  //       { totCashback: asdasdsad },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //   } catch (error) {
  //     console.log("PUTTANA EVA");
  //   }
  // };

  /////////////////////////

  const handleChangeTotCashback = (e) => {
    setTotCashback({
      ...totCashback,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSubmitTotCashback = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await client.patch(
  //       `/account/updateTotCashback/${id}`,
  //       totCashback,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     setRicarica(true);
  //     setTotCashback({
  //       totCashback: 0,
  //     });
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Error updating account.");
  //   }
  // };
  const handleSubmitTotCashback = async (e) => {
    // Ricalcola il cashback totale
    const risultatoCashbackTot = calcoloCashabackTotale();
    console.log("Cashback totale calcolato:", risultatoCashbackTot);

    // Imposta il nuovo valore di totCashback
    setTotCashback({
      totCashback: risultatoCashbackTot,
    });

    try {
      const response = await client.patch(
        `/account/updateTotCashback/${id}`,
        { totCashback: risultatoCashbackTot }, // Usa direttamente il valore calcolato
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setRicarica(true);

      // Resetta il valore di totCashback
      setTotCashback({
        totCashback: 0,
      });
    } catch (error) {
      console.error("Error:", error);
      alert("Error updating account.");
    }
  };

  // const handleSubmitTotCashback = async (e) => {
  //   // Ora che hai i dati aggiornati, ricalcola il cashback totale
  //   const risultatoCashbackTot = calcoloCashabackTotale();
  //   console.log("Cashback totale calcolato:", risultatoCashbackTot);

  //   // Imposta il nuovo valore di totCashback
  //   setTotCashback({
  //     totCashback: risultatoCashbackTot,
  //   });

  //   //////

  //   try {
  //     const response = await client.patch(
  //       `/account/updateTotCashback/${id}`,
  //       totCashback,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     setRicarica(true);
  //     setTotCashback({
  //       totCashback: 0,
  //     });
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Error updating account.");
  //   }
  // };

  // const handleCompleteSubmit = async (e) => {
  //   e.preventDefault(); // Previene il comportamento predefinito del form se necessario
  //   //setIsLoading(true);
  //   // Esegui la funzione handleSubmit
  //   try {
  //     await handleSubmit(e); // Attendi il completamento della prima funzione

  //     // // Dopo che handleSubmit è stata completata, esegui handleSubmitTotCashback
  //     // await handleSubmitTotCashback(e);

  //     // console.log("Aggiornamento completato con successo.");
  //     // setIsLoading(false);
  //     // setSuccessMessage("Fatto!");
  //     // setTimeout(async () => {
  //     //   await handleSubmitTotCashback(e);
  //     //   setSuccessMessage(null);
  //     // }, 3000);
  //   } catch (error) {
  //     setFailedMessage("Errore!");
  //     setTimeout(() => {
  //       setFailedMessage(null);
  //     }, 3000);
  //     console.error("Errore durante l'esecuzione del submit:", error);
  //   }
  //   // Imposta un timeout di 5 secondi prima di eseguire handleSubmitTotCashback
  //   setTimeout(async () => {
  //     await handleSubmitTotCashback(e); // Esegui la seconda funzione dopo 5 secondi
  //     console.log("Aggiornamento completato con successo.");
  //   }, 2000); // 5000 millisecondi = 5 secondi
  // };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoading && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      <h3 className="margin_T120 mb-4 text-center">
        Benvenuti nella tua Area Clienti dedicata.
      </h3>
      <p className="container text-center">
        Qui potrai consultare in modo semplice e intuitivo lo storico dei tuoi
        acquisti, monitorare l'ammontare del cashback accumulato e scoprire come
        sfruttarlo al meglio. Grazie al nostro programma, hai l'opportunità di
        ridurre fino al 30% le spese relative a consulenze, formazione e
        installazioni. Scopri come convertire il tuo cashback in un vantaggio
        concreto per la tua azienda.
      </p>

      <h3 className="my-4 text-center">
        Totale Cashback*: {data.account?.totCashback} €
      </h3>
      <div className="ag-theme-quartz  mb-5" style={{ height: 400 }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowSelection={"multiple"}
          defaultColDef={defaultColDef}
        />
      </div>

      <Container>
        <p>
          <strong>Come visualizzare il mio cashback: </strong> Nella tabella
          riepilogativa degli acquisti, troverai una colonna specifica che
          mostra l'importo del cashback maturato per ogni transazione e come è
          stato speso ed una voce "Totale casback" <br />
          <strong>*Come spendere il mio cashback: </strong>
          Per utilizzare il tuo cashback, è sufficiente acquistare un servizio
          SymbioTech (Consulenza, Formazione ed installazioni) e al momento del
          pagamento verrà scalato il credito maturato fino ad un massimo del 30%
          per singola voce della fattura. <br />
          <strong>Durata del Cashback: </strong>
          La scadenza del cashback è fissata al termine dell'anno solare
          successivo a quello in cui è stato maturato. Ad esempio, per gli
          acquisti effettuati nel 2024, la scadenza è il 31 dicembre 2025.
        </p>
      </Container>

      {/* <form onSubmit={handleSubmit}>
        <div>
          <label>Data:</label>
          <input
            type="text"
            name="data"
            value={formData.data}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Nr Fattura:</label>
          <input
            type="text"
            name="nrFattura"
            value={formData.nrFattura}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Descrizione:</label>
          <input
            type="text"
            name="descrizione"
            value={formData.descrizione}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Imponibile:</label>
          <input
            type="text"
            name="imponibile"
            value={formData.imponibile}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Cashback:</label>
          <input
            type="text"
            name="cashback"
            value={formData.cashback}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Update Account</button>
      </form> */}

      {session.role === "admin" && (
        <Container className="my-5">
          <h4 className="text-center mt-5 mb-2">Aggiorna totale cashback</h4>
          <Form onSubmit={handleSubmitTotCashback}>
            <Row className="mt-3 justify-content-center">
              <Form.Group as={Col} md="1">
                <Form.Label className="fw-bold">Precedente</Form.Label>
                <Form.Label className="fw-bold mt-1 d-flex justify-content-center">
                  {data.account?.totCashback} €
                </Form.Label>
              </Form.Group>
              {/* <Form.Group as={Col} md="1">
                <Form.Label className="fw-bold">Nuovo</Form.Label>
                <Form.Control
                  required
                  placeholder="0"
                  type="number"
                  name="totCashback"
                  value={totCashback.totCashback}
                  onChange={handleChangeTotCashback}
                />
              </Form.Group> */}
              <Form.Group as={Col} md="1" className="mt-4">
                <Button
                  onClick={handleSubmitTotCashback}
                  size="lg"
                  variant="dark"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Invia
                </Button>
              </Form.Group>
            </Row>
          </Form>

          <h4 className="text-center mt-5 mb-2">Aggiungi movimento</h4>
          <Form onSubmit={handleSubmit}>
            <Row className="mt-3">
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Data</Form.Label>
                <Form.Control
                  required
                  placeholder="Data"
                  type="text"
                  name="data"
                  value={formData.data}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Nr. Fattura</Form.Label>
                <Form.Control
                  required
                  placeholder="Numero Fattura"
                  type="text"
                  name="nrFattura"
                  value={formData.nrFattura}
                  onChange={handleChange}
                />
              </Form.Group>
              {/* <Form.Group as={Col} md="4">
                <Form.Label className="fw-bold">Descrizione</Form.Label>
                <Form.Control
                  required
                  size="md"
                  placeholder="Descrizione"
                  type="text"
                  name="descrizione"
                  value={formData.descrizione}
                  onChange={handleChange}
                />
              </Form.Group> */}
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Imponibile</Form.Label>
                <Form.Control
                  required
                  placeholder="Imponibile"
                  type="number"
                  name="imponibile"
                  value={formData.imponibile}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Cashback Maturato</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="cashbackPositivo"
                  value={formData.cashbackPositivo}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label className="fw-bold">Cashback Speso</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="cashbackNegativo"
                  value={formData.cashbackNegativo}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Form.Group className="mt-2">
              <Form.Label className="fw-bold">Descrizione</Form.Label>
              <Form.Control
                required
                size="md"
                placeholder="Descrizione"
                type="text"
                name="descrizione"
                value={formData.descrizione}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="d-flex mt-4 justify-content-end">
              <Button
                type="submit"
                size="lg"
                variant="dark"
                style={{
                  marginLeft: "1em",
                }}
              >
                Invia
              </Button>
            </Form.Group>
          </Form>

          {/* <button onClick={handleSubmitTotCashback}>
            Aggiorna Totale Cashback
          </button> */}
          {/* <div>
            <button onClick={handleCompleteSubmit}>
              Completa Aggiornamento Cashback
            </button>
          </div> */}
        </Container>
      )}
      {/* <Container className="my-5">
        <h4 className="text-center mt-5 mb-2">Aggiungi movimento</h4>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className="fw-bold">Descrizione</Form.Label>
            <Form.Control
              required
              size="md"
              placeholder="Descrizione"
              type="text"
              name="descrizione"
              value={formData.descrizione}
              onChange={handleChange}
            />
          </Form.Group>
          <Row className="mt-3">
            <Form.Group as={Col} md="3">
              <Form.Label className="fw-bold">Data</Form.Label>
              <Form.Control
                required
                placeholder="Data"
                type="text"
                name="data"
                value={formData.data}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label className="fw-bold">Numero Fattura</Form.Label>
              <Form.Control
                required
                placeholder="Numero Fattura"
                type="text"
                name="nrFattura"
                value={formData.nrFattura}
                onChange={handleChange}
              />{" "}
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label className="fw-bold">Imponibile</Form.Label>
              <Form.Control
                required
                placeholder="Imponibile"
                type="text"
                name="imponibile"
                value={formData.imponibile}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label className="fw-bold">Cashback</Form.Label>
              <Form.Control
                required
                placeholder="Cashback"
                type="text"
                name="cashback"
                value={formData.cashback}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Form.Group className="d-flex mt-4 justify-content-end">
            <Button type="reset" size="lg" variant="outline-dark">
              Reset
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="dark"
              style={{
                marginLeft: "1em",
              }}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Container> */}
    </>
  );
};

export default AziendaInfo;
